import React, { useEffect, useState } from "react";
import "./Live_home.css";
import { Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import Overview from "./Overview/Overview";
import Event_details from "./Event_detals/Event_details";
import { getAllLive } from "../../../../utils/apis/games";
import { useForm } from "react-hook-form";

function Live_home() {
  const [allLive, setallLive] = useState({});
  const [eventDetailsId, seteventDetailsId] = useState();
  const [eventDetails, seteventDetails] = useState();

  // const {
  //   formState: { errors },
  //   control,
  //   reset,
  //   getValues,
  //   setValue,
  // } = useForm({
  //   defaultValues: {
  //     all_live: [],
  //     event: {},
  //     event_id: 0,
  //   },
  // });

  // const getGames = async () => {
  //   const res = await getAllLive();
  //   // console.log(eventDetailsId);

  //   if (!getValues("event_id")) {
  //     await setValue("event_id", res?.data?.events[0]?.id);
  //     await setValue("event", res?.data?.events[0]);
  //     // seteventDetails(res?.events[0]);
  //     // seteventDetailsId(res?.events[0]?.id);
  //   }
  //   await setValue("all_live", res?.data);
  //   reset(getValues());
  //   // setallLive(res?.data);
  //   // setTimeout(() => {
  //   //   getGames();
  //   // }, 5000);
  // };

  // useEffect(() => {
  //   // const intervalId = setInterval(() => {
  //   getGames();
  //   // }, 3000);

  //   // return () => clearInterval(intervalId);
  // }, []);

  // console.log(getValues());

  const {
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      all_live: [],
      event: {},
      event_id: 0,
    },
  });

  const getGames = async () => {
    const res = await getAllLive();

    if (!getValues("event_id")) {
      const firstEvent = res?.data?.events[0];
      if (firstEvent) {
        await setValue("event_id", firstEvent.id);
        await setValue("event", firstEvent);
      }
    } else if (getValues("event_id")) {
      const event = res?.data?.events?.find(
        (event) => event.id === getValues("event_id")
      );
      await setValue("event", event);
    }

    await setValue("all_live", res?.data);

    // Reset the form with updated values after the state changes
    reset({
      all_live: res?.data,
      event: getValues("event"), // this ensures it doesn't overwrite the event value
      event_id: getValues("event_id"),
    });

    // Optionally log the values after reset if needed
    console.log(getValues());
  };

  // Fetch the data initially
  useEffect(() => {
    getGames();
  }, []);

  // To automatically refresh data every 5 seconds, use setInterval (optional)
  useEffect(() => {
    const intervalId = setInterval(() => {
      getGames();
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const [active, setActive] = useState("first");
  return (
    <>
      <section className="Live_home">
        <div className="tab-holder">
          <Tab.Container id="left-tabs-example" activeKey={active}>
            <div className="row me-0 ms-0">
              <div className="col-md-12 p-lg-0 p-3">
                <Nav variant="pills" className="flex">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => {
                        setActive("first");
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="tab-icon"
                        icon={faClipboardList}
                      />
                      Overview
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => {
                        setActive("second");
                      }}
                    >
                      <FontAwesomeIcon
                        className="tab-icon"
                        icon={faCalendarDays}
                      />{" "}
                      Event Detail
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-12">
                <Tab.Content className="tab-content-holder">
                  <Tab.Pane eventKey="first">
                    <Overview
                      getValues={getValues}
                      setValue={setValue}
                      // allLive={allLive}
                      // seteventDetailsId={seteventDetailsId}
                      // seteventDetails={seteventDetails}
                      setActive={setActive}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Event_details
                      getValues={getValues}
                      setValue={setValue}
                      eventDetailsId={eventDetailsId}
                      eventDetails={eventDetails}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </section>
    </>
  );
}

export default Live_home;
