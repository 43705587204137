import React, { useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Set_Password from "../Set_Password/Set_Password";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Login_Modal from "../Login_Modal/Login_Modal";

function Reset_Password(props) {
  const {
    postData,
    setSignin,
    Cookies
  } = useContext(Context);

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
    reset
  } = useForm();

  const navigate = useNavigate();
  const [passwordModalShow, setPasswordModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [emailOrContact, setEmailOrContact] = useState("");

  const handleSetPasswordClick = () => {
    props.onHide();
    setPasswordModalShow(true);
  };

  const handleBackClick = () => {
    props.onHide();
    props.loginModal();
  };

  const sendOtp = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("email_or_contact", data?.email_or_contact);

      const response = await postData(`/auth/check-exist`, DataToSend);

      if (response?.success) {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phonePattern = /^[0-9]{10}$/;

        if (emailPattern.test(data?.email_or_contact)) {
          const emailOtpResponse = await postData(`/without-login/verify-otp/email-otp-send`, { email: data?.email_or_contact });
          if (emailOtpResponse?.success) {
            console.log('OTP sent to email');
            setOtpSent(true);
          } else {
            console.log('Failed to send OTP to email');
            setError("email_or_contact", {
              type: "manual",
              message: "Failed to send OTP to email.",
            });
          }
        } else if (phonePattern.test(data?.email_or_contact)) {
          const mobileOtpResponse = await postData(`/without-login/verify-otp/mobile-otp-send`, { contact_no: data?.email_or_contact });
          if (mobileOtpResponse?.success) {
            console.log('OTP sent to mobile');
            setOtpSent(true);
          } else {
            console.log('Failed to send OTP to mobile');
            setError("email_or_contact", {
              type: "manual",
              message: "Failed to send OTP to mobile.",
            });
          }
        } else {
          console.log('Invalid email or contact number');
          setError("email_or_contact", {
            type: "manual",
            message: "Invalid email or contact number.",
          });
        }
      } else {
        if (response.message?.email_or_contact) {
          setError("email_or_contact", {
            type: "manual",
            message: response.message.email_or_contact,
          });
        }
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  const verifyOtp = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("email_or_contact", data?.email_or_contact);
      DataToSend.append("otp", data?.otp);

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const phonePattern = /^[0-9]{10}$/;

      if (emailPattern.test(data?.email_or_contact)) {
        const emailOtpResponse = await postData(`/without-login/verify-otp/email-verify-otp`, { email: data?.email_or_contact, otp: data?.otp });

        if (emailOtpResponse?.success) {
          setEmailOrContact(data?.email_or_contact);
          console.log('OTP Verified for email');
          setOtpSent(false);
          handleSetPasswordClick();
          reset()
        } else {
          console.log('Failed to Verify OTP for email');
          setError("otp", {
            type: "manual",
            message: emailOtpResponse?.message || "Failed to verify OTP for email.",
          });
        }
      } else if (phonePattern.test(data?.email_or_contact)) {
        const mobileOtpResponse = await postData(`/without-login/verify-otp/mobile-verify-otp`, { contact_no: data?.email_or_contact, otp: data?.otp });

        if (mobileOtpResponse?.success) {
          setEmailOrContact(data?.email_or_contact);
          console.log('OTP Verified for mobile');
          setOtpSent(true);
          handleSetPasswordClick();
          reset();
        } else {
          console.log('Failed to Verify OTP for mobile');
          setError("otp", {
            type: "manual",
            message: mobileOtpResponse?.message || "Failed to verify OTP for mobile.",
          });
        }
      } else {
        // If email or phone number is invalid
        console.log('Invalid email or contact number');
        setError("email_or_contact", {
          type: "manual",
          message: "Invalid email or contact number.",
        });
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError("otp", {
        type: "manual",
        message: "An error occurred while verifying the OTP.",
      });
    }
  };


  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Login_Modal Common_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-bg-img">
          <img
            className="modal-bg"
            src={process.env.PUBLIC_URL + "/assets/Images/Lohin_Modal/warri-sports.png"}
            alt="modal-bg"
          />
        </div>
        <Modal.Body>
          <div className="back-icon-holder">
            <FontAwesomeIcon
              className="back-icon"
              icon={faAngleLeft}
              onClick={() => handleBackClick()}
            />
          </div>
          <div className="modal_heading">
            <h4>Reset Password</h4>
          </div>

          <div className="form-holder">
            <Form onSubmit={handleSubmit(sendOtp)}>
              <Form.Group className="form-field" controlId="">
                <Form.Label>Enter Email or Mobile Number</Form.Label>
                <Form.Control
                  disabled={otpSent}
                  type="text"
                  placeholder="User Name"
                  {...register("email_or_contact", {
                    required: "User Name Required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.email_or_contact,
                  })}
                />
                {errors?.email_or_contact && (
                  <sup className="text-danger">
                    {errors?.email_or_contact?.message}
                  </sup>
                )}
              </Form.Group>

              {otpSent &&
                <Form.Group className="form-field" controlId="">
                  <Form.Label>OTP</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="OTP"
                    {...register("otp", {
                      required: "OTP Required",
                      pattern: {
                        value: /^[0-9]{4}$/, // Pattern ensures 4 digits
                        message: "OTP must be 4 digits",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.otp,
                    })}
                    onKeyDown={(event) => {
                      // Allow only digits (0-9) and control keys (Backspace, Delete, Arrow keys)
                      if (!/[0-9]/.test(event.key) && !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(event.key)) {
                        event.preventDefault();
                      }

                      // If 4 digits are already entered, prevent additional input
                      if (getValues("otp")?.length >= 4 && !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors?.otp && (
                    <sup className="text-danger">
                      {errors?.otp?.message}
                    </sup>
                  )}
                </Form.Group>
              }
              {otpSent ?
                <Button
                  className="common-btn"
                  onClick={handleSubmit(verifyOtp)}
                >
                  Submit
                </Button>
                :
                <Button
                  className="common-btn"
                  // type="submit"
                  onClick={handleSubmit(sendOtp)}
                >
                  Submit
                </Button>
              }

            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Set_Password
        show={passwordModalShow}
        onHide={() => setPasswordModalShow(false)}
        email_or_contact={emailOrContact}
        resetModal={props.resetModal}
      />


    </>
  );
}

export default Reset_Password;
