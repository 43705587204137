import React, { useContext, useEffect, useState } from "react";
import "./Footer.css";
import { getSocialMedia } from "../../utils/apis/app_setup/index"
import { Context } from "../../utils/context";
import { Link } from "react-router-dom";

function Footer() {

  const { IMG_URL } = useContext(Context);

  const [socialMedia, setSocialMedia] = useState([]);

  const getSocialMediaLink = async () => {
    const response = await getSocialMedia();
    if (response?.success) {
      setSocialMedia(response?.data);
    }
  };

  useEffect(() => {
    getSocialMediaLink();
  }, [])

  console.log("socialMedia :--", socialMedia);

  return (
    <>
      <section className="Footer">
        <div className="container">
          <div className="footer-logo-holder">
            <img
              className="footer-logo-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/Images/Header/betzone-logo.png"
              }
              alt="footer-logo-img"
            />
          </div>

          <div className="footer_link_container">
            <div className="row">
              <div className="col-md-4 col-sm-6 first-div">
                <div className="link-holder">
                  <div className="footer-heading-holder">
                    <h3>Quick Links</h3>
                  </div>

                  <div className="link-text-holder">
                    <p>About Us</p>
                  </div>

                  <div className="link-text-holder">
                    <p>Terms of Use</p>
                  </div>

                  <div className="link-text-holder">
                    <p>Privacy Policy</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 second-div">
                <div className="link-holder">
                  <div className="footer-heading-holder">
                    <h3>Support Links</h3>
                  </div>

                  <div className="link-text-holder">
                    <p>Help</p>
                  </div>

                  <div className="link-text-holder">
                    <p>FAQs</p>
                  </div>

                  <div className="link-text-holder">
                    <p>Contact Us</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 third-div">

                <div className="link-holder">
                  <div className="footer-heading-holder">
                    <h3>Social Media Links</h3>
                  </div>

                  <div className="footer-social-link-holder">

                    {
                      socialMedia && socialMedia?.map((item, index) => {
                        return (
                          <Link to={item?.link} target="_blank" key={index}>
                            <div className="social-link-holder">
                              <img
                                className="social-link-lmg"
                                src={IMG_URL + item.image}
                                alt={item.name}  // Using item.name as alt text for better accessibility
                              />
                            </div>
                          </Link>
                        );
                      })
                    }

                    {/* <div className="social-link-holder">
                      <img
                        className="social-link-lmg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Footer/facebook.png"
                        }
                        alt="social-link-lmg"
                      />
                    </div>


                    <div className="social-link-holder">
                      <img
                        className="social-link-lmg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Footer/instagram.png"
                        }
                        alt="social-link-lmg"
                      />
                    </div>


                    <div className="social-link-holder">
                      <img
                        className="social-link-lmg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Footer/youtube.png"
                        }
                        alt="social-link-lmg"
                      />
                    </div> */}

                  </div>

                  <div className="footer-mail-text">
                    <p>support@warrisports.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
