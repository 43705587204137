import React from "react";
import "./Home_Game_Tabs.css";

function Home_Game_Tabs() {
  // Array of game data
  const gameTabs = [
    { title: "World Cup", subtext: "(International)" },
    { title: "UEFA Nations League", subtext: "(International)" },
    { title: "UEFA Champions League", subtext: "(International Clubs)" },
    { title: "UEFA Europa League", subtext: "(International Clubs)" },
    { title: "UEFA Europa Conference League", subtext: "(International Clubs)" },
    { title: "WC Qual, CONMEBOL", subtext: "(International Clubs)" },
    { title: "Premier League", subtext: "(International Clubs)" },
    { title: "LaLiga", subtext: "(International Clubs)" },
    { title: "Bundesliga", subtext: "(Germany)" },
    { title: "Serie A", subtext: "(Italy)" }
  ];

  return (
    <section className="Home_Game_Tabs">
      <div className="container">
        <div className="Home_Game_Tabs_holder">
          <div className="row justify-content-center">
            {gameTabs.map((game, index) => (
              <div key={index} className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 Home_Game_Tabs_container">
                <div className="Home_Game_Tabs_container_main">
                  <div className="text_holder">
                    <div>
                      <h3>{game.title} <span>{game.subtext}</span></h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home_Game_Tabs;
