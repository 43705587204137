import React from "react";
import "./Live_breadcrumb.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Nav, Tab } from "react-bootstrap";


const Live_breadcrumb = () => {
  return (
    <>
      <section className="ex-home-main-section Live_breadcrumb">
        <div className="container-fluid p-0">
          <div className="breadcrumbdiv">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Live</Breadcrumb.Item>
            </Breadcrumb>           
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Live_breadcrumb;
