import React from "react";
import Modal from "react-bootstrap/Modal";
import "./Rules.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const Rules = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="Rules "
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">Rules</Modal.Title>
          <p className="football">Football</p>
        </Modal.Header>
        <div className="close-icon-holder">
          <FontAwesomeIcon
            className="close-icon"
            onClick={props.onHide}
            icon={faXmark}
          />
        </div>
        <Modal.Body>
          <div className="Bookmark">
            <div className="mark-main">
              <p className="text">Bookmaker</p>
            </div>
            <div className="content-main">
              <p className="text-main">
                If the match will not take place within 48 hours of the original
                kick-off time bets will be void.
              </p>
              <p className="text-main">
                If the selection is in a multiple bet or accumulator any refund
                must be requested before the kick-off of the first leg of the
                multiple bets.{" "}
              </p>
              <p className="text-main">
                Where a confirmed postponed match features as part of a multiple
                bet, the bet will stand on the remaining selections in the
                multiple.{" "}
              </p>
              <p className="text-main">
                Please note that games that have their kick-off altered well in
                advance to accommodate live TV, or to ease fixture congestion
                will not be classed as postponed.
              </p>
              <p className="text-main">
                If a match is forfeited or a team is given a walkover victory
                without the match having kicked off, then all bets will be void.
                Any subsequently awarded scoreline will not count for settlement
                purposes.
              </p>
            </div>
          </div>
          <div className="Bookmark">
            <div className="mark-main">
              <p className="text">Fancy</p>
            </div>
            <div className="content-main">
              <p className="text-main">
                Tournament Total Goals, Team Total Goals FT: -. scored in 90
                minutes or extra time will count.Goals scored in penalty
                shootouts do not count.
              </p>
              <p className="text-main">
                Tournament Corners - Only corners taken in 90 minutes count.{" "}
              </p>
              <p className="text-main">
                Tournament Penalties Missed/Converted - Penalties taken in 90
                minutes, extra time, and penalty shootouts all count. If a
                penalty has to be re-taken the previous disallowed penalty(ies)
                do not count.{" "}
              </p>
            </div>
          </div>
          <div className="Bookmark">
            <div className="mark-main">
              <p className="text">Match</p>
            </div>
            <div className="content-main">
              <p className="text-main">
                Tournament Total Goals, Team Total Goals FT: -. scored in 90
                minutes or extra time will count.Goals scored in penalty
                shootouts do not count.
              </p>
              <p className="text-main">
                Tournament Corners - Only corners taken in 90 minutes count.{" "}
              </p>
              <p className="text-main">
                Tournament Penalties Missed/Converted - Penalties taken in 90
                minutes, extra time, and penalty shootouts all count. If a
                penalty has to be re-taken the previous disallowed penalty(ies)
                do not count.{" "}
              </p>
              <p className="text-main">
                Tournament Total Goals, Team Total Goals FT: -. scored in 90
                minutes or extra time will count.Goals scored in penalty
                shootouts do not count.
              </p>
              <p className="text-main">
                Tournament Corners - Only corners taken in 90 minutes count.{" "}
              </p>
              <p className="text-main">
                Tournament Penalties Missed/Converted - Penalties taken in 90
                minutes, extra time, and penalty shootouts all count. If a
                penalty has to be re-taken the previous disallowed penalty(ies)
                do not count.{" "}
              </p>
              <p className="text-main">
                Tournament Total Goals, Team Total Goals FT: -. scored in 90
                minutes or extra time will count.Goals scored in penalty
                shootouts do not count.
              </p>
              <p className="text-main">
                Tournament Corners - Only corners taken in 90 minutes count.{" "}
              </p>
              <p className="text-main">
                Tournament Penalties Missed/Converted - Penalties taken in 90
                minutes, extra time, and penalty shootouts all count. If a
                penalty has to be re-taken the previous disallowed penalty(ies)
                do not count.{" "}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Rules;
