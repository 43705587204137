import React, { useState } from "react";

import "./exchangeprofile.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleLeft,
  faCalendarDays,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

const AccountStatement = () => {
  const [show, setShow] = React.useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    <>
      {/* start desktop view exchangeprofilemain-sec section  */}
      <section className="exchangeprofilemain-sec">
        <div className="secttiill">
          <div className="titlediv">
            <p className="title">Account Statement</p>
          </div>
        </div>

        <div className="tablemain-div">
          <div className="row">
            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="Select Date"
                />
                <div className="calender-icon_holder">
                  <FontAwesomeIcon
                    className="calender-icon"
                    icon={faCalendarDays}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="Select Date"
                />
                <div className="calender-icon_holder">
                  <FontAwesomeIcon
                    className="calender-icon"
                    icon={faCalendarDays}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </div>

            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <button className="submitbtnn">Submit</button>
              </div>
            </div>
          </div>

          <div className="table-div">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="">
                    <th>Date</th>
                    <th>Sr.No</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Balance</th>
                    <th>Remark</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="delete-tr">Date</td>
                    <td>Sr.No</td>
                    <td className="text-red">-50</td>
                    <td className="text-red">-5000</td>
                    <td>Balance</td>
                    <td>Remark</td>
                  </tr>

                  <tr>
                    <td className="success-tr">Date</td>
                    <td>Sr.No</td>
                    <td className="text-green">5000</td>
                    <td className="text-green">5000</td>
                    <td>Balance</td>
                    <td>Remark</td>
                  </tr>

                  <tr>
                    <td className="delete-tr">Date</td>
                    <td>Sr.No</td>
                    <td className="text-red">-50</td>
                    <td className="text-red">-5000</td>
                    <td>Balance</td>
                    <td>Remark</td>
                  </tr>

                  <tr>
                    <td>Date</td>
                    <td>Sr.No</td>
                    <td>Credit</td>
                    <td>Debit</td>
                    <td>Balance</td>
                    <td>Remark</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="tablemain-div account-setting-table">
          <div className="Values">
              <div className="sect tiill">
                <div className="titlediv">
                  <p className="title">Close</p>
                </div>
              </div>
            </div>
            <div className="table-div account-setting-table-inner">
              <div className="row">
                <Form>
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="Matched"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                      />

                      <Form.Check
                        inline
                        label="Deleted"
                        name="group1"
                        type={type}
                        id={`inline-${type}-3`}
                      />
                    </div>
                  ))}
                </Form>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Nation</th>
                      <th>Side</th>
                      <th>Rate</th>
                      <th>Amount</th>
                      <th>Win / Loss</th>
                      <th>Place Date</th>
                      <th>Match Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Dragon</td>
                      <td>Back</td>
                      <td>2</td>
                      <td>5000</td>
                      <td>5000</td>
                      <td>8/01/2024 5:58:34 PM</td>
                      <td>8/01/2024 5:58:34 PM</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            
          </div>
        </div>
      </section>
      {/* end desktop view exchangeprofilemain-sec section  */}

      {/* start exchangeMobView_secion   */}
      <section className="exchangeMobView_secion">
        <div className="toptitlediv">
          <div className="d-flex justify-content-between ">
            <div className="my-auto">
              <h6 className="profttt">
                <Link to="/exchange-profile/profile">
                  <FontAwesomeIcon className="backiccc" icon={faAngleLeft} />
                </Link>
                Account Statement
              </h6>
            </div>

            <div className="my-auto">
              <Form.Select aria-label="Default select example">
                <option>All</option>
                <option value="1">Diposite/Withdraw Report</option>
                <option value="2">Game Report</option>
              </Form.Select>
            </div>

            <div className="my-auto">
              <FontAwesomeIcon className="calendericon" icon={faCalendarDays} />
            </div>
          </div>
        </div>

        <div className="contentdivv">
          <div className="formdiv">
            <div className="text-center mb-4">
              <h6>Pick Date</h6>
            </div>

            <Form>
              <div className="formgroup">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="Start Date"
                />
              </div>
              <div className="formgroup">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="End Date"
                />
              </div>
              <div className="formgroup text-center">
                <button className="btn clearbtn">Clear</button>
                <button className="btn submitbtn">Submit</button>
              </div>
            </Form>
          </div>
        </div>

        <div className="statementlist-div"></div>
      </section>
      {/* end exchangeMobView_secion   */}
    </>
  );
};

export default AccountStatement;
