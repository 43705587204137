import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "./api";

import { Select2Data } from "./common";

export const Context = createContext();
var HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();
const AppContext = ({ children }) => {
  // const IMG_URL = "http://127.0.0.1:2999";
  const IMG_URL = "http://warrisportsnode.profcymabackend.com:2999";
  // const IMG_URL = "https://betzoneadmin.audiomeets.com/public/api";
  const [eventDetailsId, seteventDetailsId] = useState();

  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [compare, setComparelist] = useState([]);
  const [signin, setSignin] = useState(false);
  // const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  // const [isAllow, setisAllow] = useState([]);

  // const [shimmerLoader, setShimmerLoader] = useState(false);
  const [addCartModal, setAddCartModal] = useState(false);
  const minLength = 2;
  const maxLength = 30;

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const [cartCount, setCartCount] = useState(0);
  const [trigger, setTrigger] = useState(0);

  const triggerCartUpdate = () => {
    setTrigger(trigger === 0 ? 1 : 0);
  };

  const getuserData = async (id) => {
    console.log("here");

    const response = await getData(`/admin/login/${id}`, { id: id });
    await setUserData(response?.data?.data);
    console.log("response?.data?.data", response?.data?.data);
    // await setisAllow(response?.data.permissions);
  };

  // useEffect(() => {
  //   updateCartCount(signin, setCart);
  //   wishlistContext(signin, setWishlist);
  //   compareContext(signin, setComparelist);
  // }, [Cookies.get("cmj_cart"), signin]);

  // console.log("cartCountFrom COntext", cartCount);
  // console.log(usertype);
  // console.log(cart);

  const isTokenValid = async () => {
    const token = Cookies.get("betzone_security");
    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;
      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("betzone_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        // setisAllow([]);
        navigate("/");
      } else {
        setUsertype(decodedToken.type);
        getuserData(decodedToken.user);
        setSignin(true);
      }
    } else {
      // setisAllow([]);
      setSignin(false);
      setUsertype("");
      navigate("/");
    }
  };

  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        addCartModal,
        setAddCartModal,
        cartCount,
        triggerCartUpdate,
        wishlist,
        setWishlist,
        compare,
        setComparelist,
        htmlToReactParser,
        Cookies,
        cart,
        setCart,
        eventDetailsId,
        seteventDetailsId,
        Select2Data,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
