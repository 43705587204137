import React from "react";
import "./ExposureModal.css";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ExposureModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="ExposureModal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            My Market
          </Modal.Title>
        </Modal.Header>
        <div className="close-icon-holder">
          <FontAwesomeIcon
            className="close-icon"
            onClick={props.onHide}
            icon={faXmark}
          />
        </div>
        <Modal.Body>
          <div className="exchangeprofilemain-sec">
            <div className="tablemain-div ">
              <div className="table-div">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="">
                        <th>Date</th>
                        <th>Sr.No</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Balance</th>
                        <th>Remark</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Date</td>
                        <td>Sr.No</td>
                        <td>-50</td>
                        <td>-5000</td>
                        <td>Balance</td>
                        <td>Remark</td>
                      </tr>

                      <tr>
                        <td>Date</td>
                        <td>Sr.No</td>
                        <td>Credit</td>
                        <td>Debit</td>
                        <td>Balance</td>
                        <td>Remark</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="empty-data-text-holder">
                    <p>No real-time records found</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExposureModal;
