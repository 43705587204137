import { useState } from 'react';
import './Ex_right_sidebar_offcanvas.css'
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import EX_right_mybet_sidebar from '../EX_right_mybet_sidebar/EX_right_mybet_sidebar';
import Exchange_Live_Center_tab_Section from '../../../../Exchange-layout/Exchange-bet-sidebar/Exchange_Live_Center/Exchange_Live_Center_tab_Section/Exchange_Live_Center_tab_Section';


const Ex_right_sidebar_offcanvas = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            {['end'].map((placement, idx) => (
                <Offcanvas className="Ex_right_sidebar_offcanvas" show={show} onHide={handleClose} {...props} key={idx} placement={placement} name={placement}>
                    <Offcanvas.Header closeButton>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='ExchangeBetPlaceSidebar'>
                            <Exchange_Live_Center_tab_Section />
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            ))}
        </>
    )
}

export default Ex_right_sidebar_offcanvas