import React, { useState } from "react";
import "./Now_Play_modal.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Form, Modal, Nav, Tab } from "react-bootstrap";
import Match_Two_Content from "../../Match_Two_Content/Match_Two_Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faTrophy, faXmark } from "@fortawesome/free-solid-svg-icons";

function Now_Play_modal({ ...props }) {
  const [activeTab, setActiveTab] = useState("first"); // Manage active tab state

  const handleMatchClick = (tabKey) => {
    setActiveTab(tabKey); // Update the active tab
  };

  // State to manage the visibility of the content
  const [showGamePlay, setShowGamePlay] = useState(false);

  // Function to toggle the state
  const handlePlayClick = () => {
    setShowGamePlay((prevState) => !prevState); // Toggle the state between true and false
  };

  return (
    <>
      <section className="Now_Play_modal_holder">
        <Modal
          {...props}
          className="Now_Play_modal Game_Modal"
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="row">
              <div className="col-lg-9 main-section">
                <div className="close-btn trophy-icon-holder">
                  <FontAwesomeIcon className="close-icon" icon={faTrophy} />
                </div>

                <div className="close-btn close-btn-two">
                  <FontAwesomeIcon className="close-icon" icon={faXmark} />
                </div>

                <div
                  className="close-btn play-btn-holder"
                  onClick={handlePlayClick}
                >
                  <FontAwesomeIcon className="close-icon" icon={faPlay} />
                </div>
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="Now_Play_modal_swiper mySwiper"
                  speed={0} // No transition effect
                >
                  <SwiperSlide key="match-1">
                    <div className="modal-content-holder">
                      <div className="heading-holder">
                        <h4>Match Day 1</h4>
                      </div>

                      <div className="match-day-one-holder">
                        <div
                          className="match-score one"
                          onClick={() => handleMatchClick("first")}
                        >
                          <div className="row me-0 ms-0 justify-content-center ">
                            <div className="col-xl-7 col-lg-8  col-md-12">
                              <div className="match-time-holder">
                                <p>
                                  08.10 <span> / </span> 16:22
                                </p>
                              </div>
                              <div className="match-score-holder">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-12">
                                          <div className="text-holder">
                                            <p>
                                              <span className="first-team">
                                                {" "}
                                                1{" "}
                                              </span>
                                              :
                                              <span className="two-team">
                                                {" "}
                                                3{" "}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>GER</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="match-score two"
                          onClick={() => handleMatchClick("second")}
                        >
                          <div className="row me-0 ms-0 justify-content-center ">
                            <div className="col-xl-7 col-lg-8  col-md-12">
                              <div className="match-time-holder">
                                <p>
                                  08.10 <span> / </span> 16:22
                                </p>
                              </div>
                              <div className="match-score-holder">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-12">
                                          <div className="text-holder">
                                            <p>
                                              <span className="first-team">
                                                {" "}
                                                1{" "}
                                              </span>
                                              :
                                              <span className="two-team">
                                                {" "}
                                                3{" "}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>GER</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="match-score three">
                          <div className="row me-0 ms-0 justify-content-center ">
                            <div className="col-xl-7 col-lg-8  col-md-12">
                              <div className="match-time-holder">
                                <p>
                                  08.10 <span> / </span> 16:22
                                </p>
                              </div>
                              <div className="match-score-holder">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-12">
                                          <div className="text-holder">
                                            <p>
                                              <span className="first-team">
                                                {" "}
                                                1{" "}
                                              </span>
                                              :
                                              <span className="two-team">
                                                {" "}
                                                3{" "}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>GER</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="match-score four">
                          <div className="row me-0 ms-0 justify-content-center ">
                            <div className="col-xl-7 col-lg-8  col-md-12">
                              <div className="match-time-holder">
                                <p>
                                  08.10 <span> / </span> 16:22
                                </p>
                              </div>
                              <div className="match-score-holder">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-12">
                                          <div className="text-holder">
                                            <p>
                                              <span className="first-team">
                                                {" "}
                                                1{" "}
                                              </span>
                                              :
                                              <span className="two-team">
                                                {" "}
                                                3{" "}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>GER</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="match-score five">
                          <div className="row me-0 ms-0 justify-content-center ">
                            <div className="col-xl-7 col-lg-8  col-md-12">
                              <div className="match-time-holder">
                                <p>
                                  08.10 <span> / </span> 16:22
                                </p>
                              </div>
                              <div className="match-score-holder">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-12">
                                          <div className="text-holder">
                                            <p>
                                              <span className="first-team">
                                                {" "}
                                                1{" "}
                                              </span>
                                              :
                                              <span className="two-team">
                                                {" "}
                                                3{" "}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>GER</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="match-score six">
                          <div className="row me-0 ms-0 justify-content-center ">
                            <div className="col-xl-7 col-lg-8  col-md-12">
                              <div className="match-time-holder">
                                <p>
                                  08.10 <span> / </span> 16:22
                                </p>
                              </div>
                              <div className="match-score-holder">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-12">
                                          <div className="text-holder">
                                            <p>
                                              <span className="first-team">
                                                {" "}
                                                1{" "}
                                              </span>
                                              :
                                              <span className="two-team">
                                                {" "}
                                                3{" "}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="match-score-country-holder">
                                      <div className="row score-content">
                                        <div className="col-md-4">
                                          <div className="country-flag-holder">
                                            <img
                                              className="country-flag-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Images/Flags/flag-1.png"
                                              }
                                              alt="country-flag-img"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-8">
                                          <div className="text-holder">
                                            <p>GER</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide key="match-2">
                    <div className="modal-content-holder">
                      <div className="heading-holder">
                        <h4>Match Day 2</h4>
                      </div>

                      <div className="match-day-one-holder">
                        <div className="match-two-tabs-holder">
                          <Tab.Container
                            activeKey={activeTab}
                            id="left-tabs-example"
                            defaultActiveKey="first"
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <Nav variant="pills" className="tabs-holder">
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="first"
                                      onClick={() => setActiveTab("first")}
                                    >
                                      <div className="match-score">
                                        <div className="row me-0 ms-0 justify-content-center ">
                                          <div className="col-xl-12 col-lg-12  col-md-12">
                                            <div className="match-score-holder">
                                              <div className="row justify-content-center">
                                                <div className="col-4">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-6">
                                                        <div className="text-holder">
                                                          <p>ESP</p>
                                                        </div>
                                                      </div>

                                                      <div className="col-6">
                                                        <div className="country-flag-holder">
                                                          <img
                                                            className="country-flag-img"
                                                            src={
                                                              process.env
                                                                .PUBLIC_URL +
                                                              "/assets/Images/Flags/flag-1.png"
                                                            }
                                                            alt="country-flag-img"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-2">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-12">
                                                        <div className="text-holder">
                                                          <p>vs</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-4">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-6">
                                                        <div className="country-flag-holder">
                                                          <img
                                                            className="country-flag-img"
                                                            src={
                                                              process.env
                                                                .PUBLIC_URL +
                                                              "/assets/Images/Flags/flag-1.png"
                                                            }
                                                            alt="country-flag-img"
                                                          />
                                                        </div>
                                                      </div>

                                                      <div className="col-6">
                                                        <div className="text-holder">
                                                          <p>GER</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>

                                  <Nav.Item className="match-two-holder">
                                    <Nav.Link
                                      eventKey="second"
                                      onClick={() => setActiveTab("second")}
                                    >
                                      <div className="match-score">
                                        <div className="row me-0 ms-0 justify-content-center ">
                                          <div className="col-xl-12 col-lg-12  col-md-12">
                                            <div className="match-score-holder">
                                              <div className="row justify-content-center">
                                                <div className="col-4">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-6">
                                                        <div className="text-holder">
                                                          <p>ESP</p>
                                                        </div>
                                                      </div>

                                                      <div className="col-6">
                                                        <div className="country-flag-holder">
                                                          <img
                                                            className="country-flag-img"
                                                            src={
                                                              process.env
                                                                .PUBLIC_URL +
                                                              "/assets/Images/Flags/flag-1.png"
                                                            }
                                                            alt="country-flag-img"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-2">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-12">
                                                        <div className="text-holder">
                                                          <p>vs</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-4">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-6">
                                                        <div className="country-flag-holder">
                                                          <img
                                                            className="country-flag-img"
                                                            src={
                                                              process.env
                                                                .PUBLIC_URL +
                                                              "/assets/Images/Flags/flag-1.png"
                                                            }
                                                            alt="country-flag-img"
                                                          />
                                                        </div>
                                                      </div>

                                                      <div className="col-6">
                                                        <div className="text-holder">
                                                          <p>GER</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>

                                  <Nav.Item className="match-two-holder">
                                    <Nav.Link
                                      eventKey="third"
                                      onClick={() => setActiveTab("third")}
                                    >
                                      <div className="match-score">
                                        <div className="row me-0 ms-0 justify-content-center ">
                                          <div className="col-xl-12 col-lg-12  col-md-12">
                                            <div className="match-score-holder">
                                              <div className="row justify-content-center">
                                                <div className="col-4">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-6">
                                                        <div className="text-holder">
                                                          <p>ESP</p>
                                                        </div>
                                                      </div>

                                                      <div className="col-6">
                                                        <div className="country-flag-holder">
                                                          <img
                                                            className="country-flag-img"
                                                            src={
                                                              process.env
                                                                .PUBLIC_URL +
                                                              "/assets/Images/Flags/flag-1.png"
                                                            }
                                                            alt="country-flag-img"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-2">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-12">
                                                        <div className="text-holder">
                                                          <p>vs</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-4">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-6">
                                                        <div className="country-flag-holder">
                                                          <img
                                                            className="country-flag-img"
                                                            src={
                                                              process.env
                                                                .PUBLIC_URL +
                                                              "/assets/Images/Flags/flag-1.png"
                                                            }
                                                            alt="country-flag-img"
                                                          />
                                                        </div>
                                                      </div>

                                                      <div className="col-6">
                                                        <div className="text-holder">
                                                          <p>GER</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>

                                  <Nav.Item className="match-two-holder">
                                    <Nav.Link
                                      eventKey="fourth"
                                      onClick={() => setActiveTab("fourth")}
                                    >
                                      <div className="match-score">
                                        <div className="row me-0 ms-0 justify-content-center ">
                                          <div className="col-xl-12 col-lg-12  col-md-12">
                                            <div className="match-score-holder">
                                              <div className="row justify-content-center">
                                                <div className="col-4">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-6">
                                                        <div className="text-holder">
                                                          <p>ESP</p>
                                                        </div>
                                                      </div>

                                                      <div className="col-6">
                                                        <div className="country-flag-holder">
                                                          <img
                                                            className="country-flag-img"
                                                            src={
                                                              process.env
                                                                .PUBLIC_URL +
                                                              "/assets/Images/Flags/flag-1.png"
                                                            }
                                                            alt="country-flag-img"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-2">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-12">
                                                        <div className="text-holder">
                                                          <p>vs</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-4">
                                                  <div className="match-score-country-holder">
                                                    <div className="row score-content">
                                                      <div className="col-6">
                                                        <div className="country-flag-holder">
                                                          <img
                                                            className="country-flag-img"
                                                            src={
                                                              process.env
                                                                .PUBLIC_URL +
                                                              "/assets/Images/Flags/flag-1.png"
                                                            }
                                                            alt="country-flag-img"
                                                          />
                                                        </div>
                                                      </div>

                                                      <div className="col-6">
                                                        <div className="text-holder">
                                                          <p>GER</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>


                                </Nav>
                              </div>
                              <div className="col-md-12">
                                <Tab.Content className=" modal-team-content-holder">
                                  <Tab.Pane eventKey="first">
                                    {showGamePlay ? (
                                      <Match_Two_Content />
                                    ) : (
                                      <div className="row">
                                        <div className="col-md-10">
                                          <div className="live-game-vdo-holder">
                                            <div className="game-vdo-score-holder">
                                              <div className="text-holder">
                                                <p>ESP</p>
                                              </div>

                                              <div className="text-holder">
                                                <p>
                                                  <span> 2 </span> v
                                                  <span> 2 </span>
                                                </p>
                                                <p className="time-text">
                                                  16 min
                                                </p>
                                              </div>

                                              <div className="text-holder">
                                                <p>GER</p>
                                              </div>
                                            </div>
                                            <div className="game-play-vdo-holder">
                                              <img
                                                className="game-play-vdo"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/Images/Home/Banner/banner-img-1.png"
                                                }
                                                alt="game-play-vdo"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="second">
                                    <Match_Two_Content />
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="third">
                                    <Match_Two_Content />
                                  </Tab.Pane>


                                </Tab.Content>
                              </div>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>

              <div className="col-lg-3">
                <div className="modal-side-section">
                  <div className="modal-content-holder">
                    <div className="heading-holder">
                      <h4 className="text-start">Bet Slip</h4>
                    </div>

                    <div className="bet-slip-content-holder">
                      <div className="text-holder slipebgg">
                        <div className="row">
                          <div className="col-1">
                            <div className="checkbox-holder">
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicCheckbox"
                                >

                                  <Form.Check type="checkbox" />
                                </Form.Group>
                              </Form>
                            </div>
                          </div>

                          <div className="col-5">
                            <div className="slip-content-holder">
                              <p>Fulltime Result</p>
                              <h6>Sapin @1.75</h6>
                            </div>
                          </div>

                          <div className="col-5 pe-0">
                            <div className="slip-number-holder">
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Control type="" placeholder="" />
                                  <Form.Text className="form-bottom-text">
                                    Est. : FUN 0.00
                                  </Form.Text>
                                </Form.Group>
                              </Form>
                            </div>
                          </div>

                          <div className="col-1">
                            <div className="delete-cross-btn">
                              <FontAwesomeIcon className="vlrgfhrjt" icon={faXmark} />
                            </div>
                          </div>

                        </div>

                        <div className="row mt-1">
                          <div className="col-6">
                            <p className="singlesggfh">Singles (1) </p>
                          </div>
                          <div className="col-6">
                            <Form.Control value={0} className="jjhfdsfh" />
                          </div>
                        </div>
                      </div>

                      <button className="fwfjbff">Submit</button>
                    </div>

                    <div className="heading-holder">
                      <h4 className="text-start">Next Day Match</h4>
                    </div>

                    <div className="bet-slip-content-holder">
                      <div className="row mb-1">
                        <div className="col-6">
                          <div className="d-flex">
                            <div className="natdtvv">
                              <p className="sen">SEN</p>
                              <img className="secmhg" src={process.env.PUBLIC_URL + '/assets/Images/Flags/flag-1.png'} />
                            </div>
                            <div className="natdtvv mt-auto ms-2 me-2">
                              <p className="sen">VS</p>
                            </div>
                            <div className="natdtvv">
                              <p className="sen">SEN</p>
                              <img className="secmhg" src={process.env.PUBLIC_URL + '/assets/Images/Flags/flag-1.png'} />
                            </div>
                          </div>
                        </div>
                        <div className="col-6 my-auto">
                          <div className="d-flex">
                            <div className="senddd">
                              <p className="senpp">SEN</p>
                              <p className="senpp">2.19</p>
                            </div>
                            <div className="senddd">
                              <p className="senpp">SEN</p>
                              <p className="senpp">2.19</p>
                            </div>
                            <div className="senddd">
                              <p className="senpp">SEN</p>
                              <p className="senpp">2.19</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-6">
                          <div className="d-flex">
                            <div className="natdtvv">
                              <p className="sen">SEN</p>
                              <img className="secmhg" src={process.env.PUBLIC_URL + '/assets/Images/Flags/flag-1.png'} />
                            </div>
                            <div className="natdtvv mt-auto ms-2 me-2">
                              <p className="sen">VS</p>
                            </div>
                            <div className="natdtvv">
                              <p className="sen">SEN</p>
                              <img className="secmhg" src={process.env.PUBLIC_URL + '/assets/Images/Flags/flag-1.png'} />
                            </div>
                          </div>
                        </div>
                        <div className="col-6 my-auto">
                          <div className="d-flex">
                            <div className="senddd">
                              <p className="senpp">SEN</p>
                              <p className="senpp">2.19</p>
                            </div>
                            <div className="senddd">
                              <p className="senpp">SEN</p>
                              <p className="senpp">2.19</p>
                            </div>
                            <div className="senddd">
                              <p className="senpp">SEN</p>
                              <p className="senpp">2.19</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-6">
                          <div className="d-flex">
                            <div className="natdtvv">
                              <p className="sen">SEN</p>
                              <img className="secmhg" src={process.env.PUBLIC_URL + '/assets/Images/Flags/flag-1.png'} />
                            </div>
                            <div className="natdtvv mt-auto ms-2 me-2">
                              <p className="sen">VS</p>
                            </div>
                            <div className="natdtvv">
                              <p className="sen">SEN</p>
                              <img className="secmhg" src={process.env.PUBLIC_URL + '/assets/Images/Flags/flag-1.png'} />
                            </div>
                          </div>
                        </div>
                        <div className="col-6 my-auto">
                          <div className="d-flex">
                            <div className="senddd">
                              <p className="senpp">SEN</p>
                              <p className="senpp">2.19</p>
                            </div>
                            <div className="senddd">
                              <p className="senpp">SEN</p>
                              <p className="senpp">2.19</p>
                            </div>
                            <div className="senddd">
                              <p className="senpp">SEN</p>
                              <p className="senpp">2.19</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default Now_Play_modal;
