import React from 'react'
import './Virtual_breadcrumb.css'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Virtual_breadcrumb = () => {
    return (
        <>
            <section className='ex-home-main-section'>
                <div className='container-fluid p-0'>
                <div className="breadcrumbdiv">
                        <Breadcrumb>
                            <Breadcrumb.Item href="l">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">Virtual</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="gamenamediv">
                            <p className="gamename"><img className="fotticc" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/football2.png'} />Football</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Virtual_breadcrumb