import React from "react";
import "./Home_Sports.css";

function Home_Sports() {
  return (
    <>
      <section className="Home_Sports">
        <div className="container">
          <div className="Home_sports_card_section">
            <div className="row">
              <div className="col-md-4 col-6">
                <div className="Home_sports_card_holder">
                  <div className="Home_sports_card">
                    <div className="Home_sports_card_img_holder">
                      <img
                        className="Home_sports_card_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Home/Home_sports/Virtual.png"
                        }
                        alt="Home_sports_card_img"
                      />
                    </div>


                    <div className="text-holder">
                        <h3>Virtual</h3>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-4 col-6">
                <div className="Home_sports_card_holder">
                  <div className="Home_sports_card">
                    <div className="Home_sports_card_img_holder">
                      <img
                        className="Home_sports_card_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Home/Home_sports/Live.png"
                        }
                        alt="Home_sports_card_img"
                      />
                    </div>


                    <div className="text-holder">
                        <h3>Live</h3>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-4 col-6">
                <div className="Home_sports_card_holder">
                  <div className="Home_sports_card">
                    <div className="Home_sports_card_img_holder">
                      <img
                        className="Home_sports_card_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Home/Home_sports/Exchange.png"
                        }
                        alt="Home_sports_card_img"
                      />
                    </div>


                    <div className="text-holder">
                        <h3>Exchange</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home_Sports;
