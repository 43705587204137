import React from "react";
import "./Virtual_game_card_sec.css";
import Virtual_Modal from "../../../../Common_Component/Common_Modal/Virtual_Modal/Virtual_Modal";

const Virtual_game_card = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <section className="Virtual_game_card_sec">
        <div className="container">
          <div className="row">
            
            <div className="col-lg-3 col-sm-6 mb-5">
              <div className="game_card">
                <div className="img-holder">  
                  <div className="img-div">
                    <img
                      className="img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/virtual-sport/sports/one.png"
                      }
                    />
                  </div>

                  <div className="overlay-holder"></div>
                  <button
                    className="playbtn"
                    onClick={() => setModalShow(true)}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/virtual-sport/icons/play-btn.png"
                      }
                    />
                    Play
                  </button>
                </div>

                <div className="cardname">
                  <p className="name">Virtual Football League - England</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-5">
              <div className="game_card">
                <div className="img-holder">
                  <div className="img-div">
                    <img
                      className="img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/virtual-sport/sports/two.png"
                      }
                    />
                  </div>
                  <div className="overlay-holder"></div>
                  <button
                    className="playbtn"
                    onClick={() => setModalShow(true)}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/virtual-sport/icons/play-btn.png"
                      }
                    />
                    Play
                  </button>
                </div>

                <div className="cardname">
                  <p className="name">Spain League On Demand</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 mb-5">
              <div className="game_card">
                <div className="img-holder">
                  <div className="img-div">
                    <img
                      className="img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/virtual-sport/sports/three.png"
                      }
                    />
                  </div>
                  <div className="overlay-holder"></div>
                  <button
                    className="playbtn"
                    onClick={() => setModalShow(true)}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/virtual-sport/icons/play-btn.png"
                      }
                    />
                    Play
                  </button>
                </div>

                <div className="cardname">
                  <p className="name">Virtual Football League - England</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 mb-5">
              <div className="game_card">
                <div className="img-holder">
                  <div className="img-div">
                    <img
                      className="img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/virtual-sport/sports/four.png"
                      }
                    />
                  </div>
                  <div className="overlay-holder"></div>
                </div>
                <button className="playbtn" onClick={() => setModalShow(true)}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Images/virtual-sport/icons/play-btn.png"
                    }
                  />
                  Play
                </button>

                <div className="cardname">
                  <p className="name">Spain League On Demand</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <Virtual_Modal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Virtual_game_card;
