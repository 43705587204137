import React, { useContext, useState } from "react";
import "../Exchange-home/Exchange_home.css";
import "./Exchange_match_bet.css";
import { useLocation } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import ReactDOM from "react-dom";
import Countdown from "react-countdown";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import ProgressBar from "react-bootstrap/ProgressBar";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import MultiProgress from "react-multi-progress";

const Exchange_match_bet = () => {
  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const leftPercentage = 100;
  const rightPercentage = 63;

  return (
    <>
      <section className="ex-home-main-section">
        <div className="container-fluid p-0">
          <div className="breadcrumbdiv">
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Football</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Match</Breadcrumb.Item>
            </Breadcrumb>

            <div className="gamenamediv ">
              <p className="gamename">
                <img
                  className="fotticc"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Exchange/icons/football2.png"
                  }
                />
                FK Rigas Futbola Skola v Bodoe/Glimt
              </p>
            </div>
            <p className="dateandtime">
              8/5/2024 <span className="ms-2">12:00:00 PM</span>
            </p>
          </div>

          {/* desktop view games-accodion-sect design start */}
          <div className="games-accodion-sect games-accodion-sect-deskview">
            {/* 1st Half div start  */}
            <div className="matchdetailsdiv">
              <div className="oddnamediv">
                <p className="odname">1st Half</p>
              </div>
              <div className="row px-3 py-1 ">

                <div className="col-xl-5">
                  <div className="coundowndiv">
                    <Countdown
                      date={Date.now() + 50000000}
                      renderer={renderer}
                    />
                  </div>
                  <div className="game-points-div">
                    <div className="row head">
                      <div className="col-4 p-0">
                        <div className="d-flex bgg1">
                          <div className="shirtdiv">
                            <img
                              className="shirt"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Images/Exchange/livecenter/shirt1.png"
                              }
                            />
                          </div>
                          <div className="my-auto ms-1">
                            <p className="gnme1">FK Rigas FutFuFutFu</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 text-center midpoint p-0 ">
                        <p>0:1</p>
                      </div>
                      <div className="col-4 p-0">
                        <div className="d-flex bgg1">
                          <div className="my-auto ms-1">
                            <p className="gnme1">Bodoe/Glimt</p>
                          </div>
                          <div className="shirtdiv">
                            <img
                              className="shirt"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Images/Exchange/livecenter/shirt2.png"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row scorediv">
                      <div className="col-2 colwidth p-0">
                        <div className="points">
                          <p className="no">4</p>
                          <img
                            className="iccsm"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/first-half/corner.png"
                            }
                          />
                          <p className="no">4</p>
                        </div>
                      </div>
                      <div className="col-2 colwidth p-0">
                        <div className="points">
                          <p className="no">4</p>
                          <img
                            className="iccsm"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/first-half/Rectangle1.png"
                            }
                          />
                          <p className="no">4</p>
                        </div>
                      </div>
                      <div className="col-2 colwidth p-0">
                        <div className="points">
                          <p className="no">4</p>
                          <img
                            className="iccsm"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/first-half/Rectangle2.png"
                            }
                          />
                          <p className="no">4</p>
                        </div>
                      </div>
                      <div className="col-2 colwidth p-0">
                        <div className="points">
                          <p className="no">4</p>
                          <img
                            className="iccsm"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/first-half/offsides.png"
                            }
                          />
                          <p className="no">4</p>
                        </div>
                      </div>
                      <div className="col-2 colwidth p-0">
                        <div className="points">
                          <p className="no">4</p>
                          <img
                            className="iccsm"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/first-half/Group.png"
                            }
                          />
                          <p className="no">4</p>
                        </div>
                      </div>
                    </div>
                    <div className="matchscreendiv">
                      <img
                        className="screen"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Exchange/livecenter/football-game.png"
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xl-7">
                  <div className="matchdetdiv">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="Heat"
                    >
                      <Nav variant="pills" className="">
                        <Nav.Item>
                          <Nav.Link eventKey="Heat">Heat Map</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="H2H">H2H</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Stats">Stats</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Standings">Standings</Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content>
                        <Tab.Pane eventKey="Heat">
                          <div className="tab-cont">
                            <div className="ground-div">
                              <img
                                className="groundimg"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Images/Exchange/livecenter/goals.png"
                                }
                              />

                              <div className="goalsiccc">
                                <div className="listdiv">
                                  <div className="icdiv">
                                    <img className="img" src={process.env.PUBLIC_URL + "/assets/Images/Exchange/icons/heatmap/one.png"} />
                                  </div>
                                  <div className="icdiv">
                                    <img className="img" src={process.env.PUBLIC_URL + "/assets/Images/Exchange/icons/heatmap/two.png"} />
                                  </div>
                                  <div className="icdiv">
                                    <img className="img" src={process.env.PUBLIC_URL + "/assets/Images/Exchange/icons/heatmap/three.png"} />
                                  </div>
                                  <div className="icdiv">
                                    <img className="img" src={process.env.PUBLIC_URL + "/assets/Images/Exchange/icons/heatmap/four.png"} />
                                  </div>
                                  <div className="icdiv">
                                    <img className="img" src={process.env.PUBLIC_URL + "/assets/Images/Exchange/icons/heatmap/five.png"} />
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="H2H">
                          <div className="tab-cont">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="Total"
                            >
                              <Nav variant="pills" className="">
                                <Nav.Item>
                                  <Nav.Link eventKey="Total">Total</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="Form">Form</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="Stats2">Stats</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="Matches">Matches</Nav.Link>
                                </Nav.Item>
                              </Nav>
                              <Tab.Content>

                                <Tab.Pane eventKey="Total">
                                  <div className="h2h-total mt-4">
                                    <div className="twovsgm">
                                      <div className="row">
                                        <div className="col-4">
                                          <div className="first">
                                            <div className="imdiv">
                                              <img
                                                className="icon"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/Images/Exchange/livecenter/Group1.png"
                                                }
                                              />
                                            </div>
                                            <p className="contr">
                                              FK Rigas Futbola Skola
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-4 text-center my-auto">
                                          <p className="vs">VS</p>
                                        </div>
                                        <div className="col-4">
                                          <div className="first">
                                            <div className="imdiv">
                                              <img
                                                className="icon"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/Images/Exchange/livecenter/Group1.png"
                                                }
                                              />
                                            </div>
                                            <p className="contr">Bodoe/Glimt</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="h2h_holder range-bar">
                                      <div className="multi-progress-bar-section">
                                        <div className="multi-progress-bar_holder">
                                          <div className="progressbar-lable-holder">
                                            <div className="row">
                                              <div className="col-md-4">
                                                <div className="label-text text-start">
                                                  <p>38 % - ESP</p>
                                                </div>
                                              </div>

                                              <div className="col-md-4">
                                                <div className="label-text text-center">
                                                  <p>Shots on target</p>
                                                </div>
                                              </div>

                                              <div className="col-md-4">
                                                <div className="label-text text-end">
                                                  <p>GER - 29 %</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <MultiProgress
                                            className="main_progress_bar_class"
                                            elements={[
                                              {
                                                value: 20,
                                                color: "#5D1628",
                                                className: "progress_bar_css",
                                                // // showPercentage: true,
                                                fontSize: 7,
                                                textColor: "white",
                                                isBold: true,
                                              },

                                              {
                                                value: 20,
                                                color: "#E6304E",
                                                className: "progress_bar_css",
                                                // showPercentage: true,
                                                fontSize: 7,
                                                textColor: "white",
                                                isBold: true,
                                              },

                                              {
                                                value: 20,
                                                color: "#F4C327",
                                                className: "progress_bar_css",
                                                // showPercentage: true,
                                                fontSize: 7,
                                                textColor: "white",
                                                isBold: true,
                                              },

                                              {
                                                value: 20,
                                                color: "#14C2A1",
                                                className: "progress_bar_css",
                                                // showPercentage: true,
                                                fontSize: 7,
                                                textColor: "white",
                                                isBold: true,
                                              },

                                              {
                                                value: 20,
                                                color: "#5D1628",
                                                className: "progress_bar_css",
                                                // showPercentage: true,
                                                fontSize: 7,
                                                textColor: "white",
                                                isBold: true,
                                              },
                                            ]}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>


                                <Tab.Pane eventKey="Form">
                                  <div className="h2h-total mt-4">
                                    <div className="twovsgm">
                                      <div className="row">
                                        <div className="col-4">
                                          <div className="first">
                                            <div className="imdiv">
                                              <img
                                                className="icon"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/Images/Exchange/livecenter/Group1.png"
                                                }
                                              />
                                            </div>
                                            <p className="contr">
                                              FK Rigas Futbola Skola
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-4 text-center my-auto">
                                          <p className="vs">VS</p>
                                        </div>
                                        <div className="col-4">
                                          <div className="first">
                                            <div className="imdiv">
                                              <img
                                                className="icon"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/Images/Exchange/livecenter/Group1.png"
                                                }
                                              />
                                            </div>
                                            <p className="contr">Bodoe/Glimt</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="homeairo">
                                      <div className="row">
                                        <div className="col-5 ">
                                          <div className="d-flex">
                                            <p className="Ldiv">L</p>
                                            <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/home.png'} />
                                            <p className="list"><span>1:4</span>Shan United</p>
                                          </div>
                                          <div className="d-flex">
                                            <p className="Ldiv">L</p>
                                            <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/home.png'} />
                                            <p className="list"><span>1:4</span>Shan United</p>
                                          </div>
                                          <div className="d-flex">
                                            <p className="Ldiv">L</p>
                                            <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/airoplane.png'} />
                                            <p className="list"><span>1:4</span>Shan United</p>
                                          </div>
                                          <div className="d-flex">
                                            <p className="Ldiv">L</p>
                                            <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/airoplane.png'} />
                                            <p className="list"><span>1:4</span>Shan United</p>
                                          </div>
                                        </div>
                                        <div className="col-2 p-0 text-center">
                                          <div className="bd-cent"></div>
                                        </div>
                                        <div className="col-5">
                                          <div className="d-flex justify-content-end">
                                            <p className="list"><span>1:4</span>Shan United</p>
                                            <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/airoplane.png'} />

                                            <p className="Ldiv">L</p>
                                          </div>
                                          <div className="d-flex justify-content-end">
                                            <p className="list"><span>1:4</span>Shan United</p>
                                            <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/home.png'} />

                                            <p className="Ldiv">L</p>
                                          </div>
                                          <div className="d-flex justify-content-end">
                                            <p className="list"><span>1:4</span>Shan United</p>
                                            <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/home.png'} />

                                            <p className="Ldiv">L</p>
                                          </div>
                                          <div className="d-flex justify-content-end">
                                            <p className="list"><span>1:4</span>Shan United</p>
                                            <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/home.png'} />

                                            <p className="Ldiv">L</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>


                                <Tab.Pane eventKey="Stats2">
                                  <div className="h2h-total mt-4">
                                    <div className="twovsgm">
                                      <div className="row">
                                        <div className="col-4">
                                          <div className="first">
                                            <div className="imdiv">
                                              <img
                                                className="icon"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/Images/Exchange/livecenter/Group1.png"
                                                }
                                              />
                                            </div>
                                            <p className="contr">
                                              FK Rigas Futbola Skola
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-4 text-center my-auto">
                                          <p className="vs">VS</p>
                                        </div>
                                        <div className="col-4">
                                          <div className="first">
                                            <div className="imdiv">
                                              <img
                                                className="icon"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/Images/Exchange/livecenter/Group1.png"
                                                }
                                              />
                                            </div>
                                            <p className="contr">Bodoe/Glimt</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="avgp">
                                      <p>Averages</p>
                                    </div>


                                    <div className="avgoverflow">
                                      <div className="h2h_holder range-bar py-0">
                                        <div className="multi-progress-bar-section">
                                          <div className="multi-progress-bar_holder">
                                            <div className="progressbar-lable-holder">
                                              <div className="row">
                                                <div className="col-md-2">
                                                  <div className="label-text text-start">
                                                    <p>0.75</p>
                                                  </div>
                                                </div>

                                                <div className="col-md-8">
                                                  <div className="label-text text-center">
                                                    <p>Average goals scored</p>
                                                  </div>
                                                </div>

                                                <div className="col-md-2">
                                                  <div className="label-text text-end">
                                                    <p>0.5</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <MultiProgress
                                              className="main_progress_bar_class"
                                              elements={[
                                                {
                                                  value: 20,
                                                  color: "#5D1628",
                                                  className: "progress_bar_css",
                                                  // // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#E6304E",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#F4C327",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#14C2A1",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#5D1628",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },
                                              ]}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="h2h_holder range-bar py-0">
                                        <div className="multi-progress-bar-section">
                                          <div className="multi-progress-bar_holder">
                                            <div className="progressbar-lable-holder">
                                              <div className="row">
                                                <div className="col-md-2">
                                                  <div className="label-text text-start">
                                                    <p>0.75</p>
                                                  </div>
                                                </div>

                                                <div className="col-md-8">
                                                  <div className="label-text text-center">
                                                    <p>Average goals scored</p>
                                                  </div>
                                                </div>

                                                <div className="col-md-2">
                                                  <div className="label-text text-end">
                                                    <p>0.5</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <MultiProgress
                                              className="main_progress_bar_class"
                                              elements={[
                                                {
                                                  value: 20,
                                                  color: "#5D1628",
                                                  className: "progress_bar_css",
                                                  // // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#E6304E",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#F4C327",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#14C2A1",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#5D1628",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },
                                              ]}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="h2h_holder range-bar py-0">
                                        <div className="multi-progress-bar-section">
                                          <div className="multi-progress-bar_holder">
                                            <div className="progressbar-lable-holder">
                                              <div className="row">
                                                <div className="col-md-2">
                                                  <div className="label-text text-start">
                                                    <p>0.75</p>
                                                  </div>
                                                </div>

                                                <div className="col-md-8">
                                                  <div className="label-text text-center">
                                                    <p>Average goals scored</p>
                                                  </div>
                                                </div>

                                                <div className="col-md-2">
                                                  <div className="label-text text-end">
                                                    <p>0.5</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <MultiProgress
                                              className="main_progress_bar_class"
                                              elements={[
                                                {
                                                  value: 20,
                                                  color: "#5D1628",
                                                  className: "progress_bar_css",
                                                  // // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#E6304E",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#F4C327",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#14C2A1",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },

                                                {
                                                  value: 20,
                                                  color: "#5D1628",
                                                  className: "progress_bar_css",
                                                  // showPercentage: true,
                                                  fontSize: 7,
                                                  textColor: "white",
                                                  isBold: true,
                                                },
                                              ]}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                </Tab.Pane>


                                <Tab.Pane eventKey="Matches">
                                  <div className="h2h-total mt-4 py-2">
                                    <div className="fkrigasoevr">
                                      <div className="fk-rigas">

                                        <div className="head">
                                          <div className="last">
                                            <p className="lmt">Last</p>
                                            <p className="lmt">Matches</p>
                                            <p className="big5">5</p>
                                          </div>
                                          <div className="name">
                                            <p>FK Rigas Futbola Skola</p>
                                          </div>
                                        </div>

                                        <div className="listdiv">
                                          <div className="row">
                                            <div className="col-8">
                                              <p className="datep">28/07/2024 &nbsp; <span>National League</span></p>
                                              <p className="fk"><img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group2.png'} /> &nbsp;FK Rigas Futbola Skola  &nbsp;<span>1 : 4</span></p>
                                            </div>
                                            <div className="col-4 text-end my-auto">
                                              <p className="fk">FK Rigas  &nbsp;<img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} /></p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="listdiv">
                                          <div className="row">
                                            <div className="col-8">
                                              <p className="datep">28/07/2024 &nbsp; <span>National League</span></p>
                                              <p className="fk"><img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group2.png'} /> &nbsp;FK Rigas Futbola Skola  &nbsp;<span>1 : 4</span></p>
                                            </div>
                                            <div className="col-4 text-end my-auto">
                                              <p className="fk">FK Rigas  &nbsp;<img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} /></p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="listdiv">
                                          <div className="row">
                                            <div className="col-8">
                                              <p className="datep">28/07/2024 &nbsp; <span>National League</span></p>
                                              <p className="fk"><img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group2.png'} /> &nbsp;FK Rigas Futbola Skola  &nbsp;<span>1 : 4</span></p>
                                            </div>
                                            <div className="col-4 text-end my-auto">
                                              <p className="fk">FK Rigas  &nbsp;<img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} /></p>
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                </Tab.Pane>


                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </Tab.Pane>



                        <Tab.Pane eventKey="Stats">
                          <div className="tab-cont mt-4">
                            <div className="stats-overflow">
                              <div className="range-bar mb-3">
                                <p className="kicks">Total shots</p>
                                {/* <div className="range-bar-container">
                                  <div className="percentage-text">{leftPercentage}%</div>
                                  <div className="bar-container">
                                    <div className="bar left-bar" style={{ width: `${leftPercentage}%`, marginRight: "4px" }} />
                                    <div className="bar right-bar" style={{ width: `${rightPercentage}%` }} />
                                  </div>
                                  <div className="percentage-text">{rightPercentage}%</div>
                                </div> */}

                                <div className="multi-progress-bar-section">
                                  <div className="multi-progress-bar_holder">
                                    <div className="progressbar-lable-holder">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="label-text text-start">
                                            <p>38 % - ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-center">
                                            <p>Shots on target</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-end">
                                            <p>GER - 29 %</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <MultiProgress
                                      className="main_progress_bar_class"
                                      elements={[
                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#E6304E",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#F4C327",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#14C2A1",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },
                                      ]}
                                    />
                                  </div>

                                  <div className="multi-progress-bar_holder">
                                    <div className="progressbar-lable-holder">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="label-text text-start">
                                            <p>38 % - ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-center">
                                            <p>Shots on target</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-end">
                                            <p>GER - 29 %</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <MultiProgress
                                      className="main_progress_bar_class"
                                      elements={[
                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#E6304E",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#F4C327",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#14C2A1",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },
                                      ]}
                                    />
                                  </div>

                                  <div className="multi-progress-bar_holder">
                                    <div className="progressbar-lable-holder">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="label-text text-start">
                                            <p>38 % - ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-center">
                                            <p>Shots on target</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-end">
                                            <p>GER - 29 %</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <MultiProgress
                                      className="main_progress_bar_class"
                                      elements={[
                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#E6304E",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#F4C327",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#14C2A1",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },
                                      ]}
                                    />
                                  </div>

                                  <div className="multi-progress-bar_holder">
                                    <div className="progressbar-lable-holder">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="label-text text-start">
                                            <p>38 % - ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-center">
                                            <p>Shots on target</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-end">
                                            <p>GER - 29 %</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <MultiProgress
                                      className="main_progress_bar_class"
                                      elements={[
                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#E6304E",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#F4C327",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#14C2A1",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },
                                      ]}
                                    />
                                  </div>

                                  <div className="multi-progress-bar_holder">
                                    <div className="progressbar-lable-holder">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="label-text text-start">
                                            <p>38 % - ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-center">
                                            <p>Shots on target</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-end">
                                            <p>GER - 29 %</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <MultiProgress
                                      className="main_progress_bar_class"
                                      elements={[
                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#E6304E",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#F4C327",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#14C2A1",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          // showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },
                                      ]}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>


                        <Tab.Pane eventKey="Standings">
                          <div className="tab-cont">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="Total1">
                              <Nav variant="pills" className="">
                                <Nav.Item>
                                  <Nav.Link eventKey="Total1">League</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="Form3">Home</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="Stats3">Away</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="Matches3">Form</Nav.Link>
                                </Nav.Item>
                              </Nav>

                              <Tab.Content>

                                <Tab.Pane eventKey="Total1">
                                  <div className="h2h-total py-0">
                                    <div class="avgp"><p>Regular Season</p></div>

                                    <div className="leaguetable table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Team</th>
                                            <th>MP</th>
                                            <th>PTS</th>
                                            <th>W</th>
                                            <th>D</th>
                                            <th>L</th>
                                            <th>GD</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>1</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>2</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>3</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>4</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>5</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>6</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="Form3">
                                  <div className="h2h-total py-0">
                                    <div class="avgp"><p>Regular Season</p></div>

                                    <div className="leaguetable table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Team</th>
                                            <th>MP</th>
                                            <th>PTS</th>
                                            <th>W</th>
                                            <th>D</th>
                                            <th>L</th>
                                            <th>GD</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>1</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>2</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>3</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>4</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>5</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>6</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="Stats3">
                                  <div className="h2h-total py-0">
                                    <div class="avgp"><p>Regular Season</p></div>

                                    <div className="leaguetable table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Team</th>
                                            <th>MP</th>
                                            <th>PTS</th>
                                            <th>W</th>
                                            <th>D</th>
                                            <th>L</th>
                                            <th>GD</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>1</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>2</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>3</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>4</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>5</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                          <tr>
                                            <td>6</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                            <td className="bgdrk"><span>5</span></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="Matches3">
                                  <div className="h2h-total py-0">
                                    <div class="avgp"><p>Regular Season</p></div>

                                    <div className="leaguetable table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Team</th>
                                            <th>Form</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>1</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk">
                                              <span className="red">5</span>
                                              <span className="red">5</span>
                                              <span className="yellow">5</span>
                                              <span className="green">5</span>
                                              <span className="green">5</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>1</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk">
                                              <span className="red">5</span>
                                              <span className="red">5</span>
                                              <span className="yellow">5</span>
                                              <span className="green">5</span>
                                              <span className="green">5</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>1</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk">
                                              <span className="red">5</span>
                                              <span className="red">5</span>
                                              <span className="yellow">5</span>
                                              <span className="green">5</span>
                                              <span className="green">5</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>1</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk">
                                              <span className="red">5</span>
                                              <span className="red">5</span>
                                              <span className="yellow">5</span>
                                              <span className="green">5</span>
                                              <span className="green">5</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>1</td>
                                            <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                            <td className="bgdrk">
                                              <span className="red">5</span>
                                              <span className="red">5</span>
                                              <span className="yellow">5</span>
                                              <span className="green">5</span>
                                              <span className="green">5</span>
                                            </td>
                                          </tr>

                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Tab.Pane>

                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </Tab.Pane>

                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>


            {/* 1st Half div end  */}

            {/* match odds div start  */}
            <div className="matchdetailsdiv">
              <div className="oddnamediv">
                <p className="odname">Match Odds</p>
              </div>
              <p className="maxmin">
                <span>Min: 100 </span>
                <span>Max: 50K</span>
              </p>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="redno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <p className="backtitle">BACK</p>
                      <div className="">
                        <p className="c1 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c3 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>

                    <div className="greenmain">
                      <p className="laytitle">LAY</p>
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c1 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="greenno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c1 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c3 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>

                    <div className="greenmain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c1 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="greenno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c1 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c3 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>

                    <div className="greenmain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c1 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* match odds div end  */}

            {/* Bookmarker  div start  */}
            <div className="matchdetailsdiv">
              <div className="oddnamediv">
                <p className="odname">Bookmarker</p>
              </div>
              <p className="maxmin">
                <span>Min: 100 </span>
                <span>Max: 50K</span>
              </p>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <p className="backtitle">BACK</p>
                      <div className="">
                        <p className="c3 nobox">-</p>
                        <span className="nopoint"></span>
                      </div>
                    </div>
                    <div className="greenmain">
                      <p className="laytitle">LAY</p>
                      <div className="">
                        <p className="c3 nobox">-</p>
                        <span className="nopoint"></span>
                      </div>
                    </div>
                    <p className="suspend">Suspended</p>
                  </div>
                </div>
                <div className="suspended-overlay"></div>
              </div>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">51</p>
                        <span className="nopoint">40000</span>
                      </div>
                    </div>
                    <div className="greenmain">
                      <div className="">
                        <p className="c3 nobox">44</p>
                        <span className="nopoint">1555</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">-</p>
                        <span className="nopoint"></span>
                      </div>
                    </div>
                    <div className="greenmain">
                      <div className="">
                        <p className="c3 nobox">-</p>
                        <span className="nopoint"></span>
                      </div>
                    </div>
                    <p className="suspend">Suspended</p>
                  </div>
                </div>
                <div className="suspended-overlay"></div>
              </div>
            </div>
            {/* Bookmarker div end  */}

            {/* OVER UNDER 15  div start  */}
            <div className="matchdetailsdiv">
              <div className="oddnamediv">
                <p className="odname">OVER UNDER 15</p>
              </div>
              <p className="maxmin">
                <span>Min: 100 </span>
                <span>Max: 50K</span>
              </p>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <p className="backtitle">BACK</p>
                      <div className="">
                        <p className="c1 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c3 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>

                    <div className="greenmain">
                      <p className="laytitle">LAY</p>
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c1 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c1 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c3 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>

                    <div className="greenmain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c1 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c1 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c3 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>

                    <div className="greenmain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c1 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* OVER UNDER 15 div end  */}

            {/* OVER UNDER 25  div start  */}
            <div className="matchdetailsdiv">
              <div className="oddnamediv">
                <p className="odname">OVER UNDER 25</p>
              </div>
              <p className="maxmin">
                <span>Min: 100 </span>
                <span>Max: 50K</span>
              </p>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <p className="backtitle">BACK</p>
                      <div className="">
                        <p className="c1 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c3 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>

                    <div className="greenmain">
                      <p className="laytitle">LAY</p>
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c1 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c1 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c3 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>

                    <div className="greenmain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c1 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c1 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c3 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>

                    <div className="greenmain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c2 nobox">16</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className="c1 nobox">17</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* OVER UNDER 25 div end  */}

            <div className="row">
              <div className="col-md-6 mb-4">
                {/* 1st Period div start  */}
                <div className="matchdetailsdiv">
                  <div className="oddnamediv">
                    <p className="odname">1st Period Winner</p>
                  </div>
                  <p className="maxmin">
                    <span>Min: 100 </span>
                    <span>Max: 50K</span>
                  </p>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <p className="backtitle">BACK</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <p className="laytitle">LAY</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 1st Period div end  */}
              </div>

              <div className="col-md-6 mb-4">
                {/* 2nd Period Winner  div start  */}
                <div className="matchdetailsdiv">
                  <div className="oddnamediv">
                    <p className="odname">2nd Period Winner</p>
                  </div>
                  <p className="maxmin">
                    <span>Min: 100 </span>
                    <span>Max: 50K</span>
                  </p>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <p className="backtitle">BACK</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <p className="laytitle">LAY</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd Period Winner div end  */}
              </div>

              <div className="col-md-6 mb-4">
                {/* Next Goal 2.0  div start  */}
                <div className="matchdetailsdiv">
                  <div className="oddnamediv">
                    <p className="odname">Next Goal 2.0</p>
                  </div>
                  <p className="maxmin">
                    <span>Min: 100 </span>
                    <span>Max: 50K</span>
                  </p>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <p className="backtitle">BACK</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <p className="laytitle">LAY</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Next Goal 2.0 div end  */}
              </div>

              <div className="col-md-6 mb-4">
                {/* Draw No Bet  div start  */}
                <div className="matchdetailsdiv">
                  <div className="oddnamediv">
                    <p className="odname">Draw No Bet</p>
                  </div>
                  <p className="maxmin">
                    <span>Min: 100 </span>
                    <span>Max: 50K</span>
                  </p>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <p className="backtitle">BACK</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <p className="laytitle">LAY</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Draw No Bet div end  */}
              </div>

              <div className="col-md-6 mb-4">
                {/* Both Teams to Score  div start  */}
                <div className="matchdetailsdiv">
                  <div className="oddnamediv">
                    <p className="odname">Both Teams to Score</p>
                  </div>
                  <p className="maxmin">
                    <span>Min: 100 </span>
                    <span>Max: 50K</span>
                  </p>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <p className="backtitle">BACK</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <p className="laytitle">LAY</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Next Goal 2.0 div end  */}
              </div>

              <div className="col-md-6 mb-4">
                {/* Both Teams to Score 1st Half  div start  */}
                <div className="matchdetailsdiv">
                  <div className="oddnamediv">
                    <p className="odname">Both Teams to Score 1st Half</p>
                  </div>
                  <p className="maxmin">
                    <span>Min: 100 </span>
                    <span>Max: 50K</span>
                  </p>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <p className="backtitle">BACK</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <p className="laytitle">LAY</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Both Teams to Score 1st Half div end  */}
              </div>

              <div className="col-md-6 mb-4">
                {/* Both Teams to Score 2nd Half  div start  */}
                <div className="matchdetailsdiv">
                  <div className="oddnamediv">
                    <p className="odname">Both Teams to Score 2nd Half</p>
                  </div>
                  <p className="maxmin">
                    <span>Min: 100 </span>
                    <span>Max: 50K</span>
                  </p>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <p className="backtitle">BACK</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <p className="laytitle">LAY</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Both Teams to Score 2nd Half end  */}
              </div>

              <div className="col-md-6 mb-4">
                {/* Goals Odd / Even  div start  */}
                <div className="matchdetailsdiv">
                  <div className="oddnamediv">
                    <p className="odname">Goals Odd / Even</p>
                  </div>
                  <p className="maxmin">
                    <span>Min: 100 </span>
                    <span>Max: 50K</span>
                  </p>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <p className="backtitle">BACK</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <p className="laytitle">LAY</p>
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backlaydiv">
                    <div className="d-flex mainflex ">
                      <div className="namesecdiv">
                        <p className="matchname">FK Rigas Futbola Skola</p>
                        <p className="blackno">-2700</p>
                      </div>
                      <div className="backlediv">
                        <div className="yellomain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>

                        <div className="greenmain">
                          <div className="">
                            <p className="c3 nobox">15.5</p>
                            <span className="nopoint">25</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Goals Odd / Even div end  */}
              </div>
            </div>

            {/* HT / FT  div start  */}
            <div className="matchdetailsdiv">
              <div className="oddnamediv">
                <p className="odname">HT / FT</p>
              </div>
              <p className="maxmin">
                <span>Min: 100 </span>
                <span>Max: 50K</span>
              </p>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno redno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className=" nobox nonnn">1/X</p>
                        <span className="nopoint redno">25</span>
                      </div>
                    </div>
                  </div>

                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className=" nobox nonnn">1/2</p>
                        <span className="nopoint redno">25</span>
                      </div>
                    </div>
                  </div>

                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno greenno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className=" nobox nonnn">X/X</p>
                        <span className="nopoint redno">25</span>
                      </div>
                    </div>
                  </div>

                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className=" nobox nonnn">1/2</p>
                        <span className="nopoint redno">25</span>
                      </div>
                    </div>
                  </div>

                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="backlaydiv">
                <div className="d-flex mainflex ">
                  <div className="namesecdiv">
                    <p className="matchname">FK Rigas Futbola Skola</p>
                    <p className="blackno redno">-2700</p>
                  </div>
                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint ">25</span>
                      </div>
                      <div className="">
                        <p className=" nobox nonnn">1/X</p>
                        <span className="nopoint redno">25</span>
                      </div>
                    </div>
                  </div>

                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                      <div className="">
                        <p className=" nobox nonnn">1/2</p>
                        <span className="nopoint redno">25</span>
                      </div>
                    </div>
                  </div>

                  <div className="backlediv">
                    <div className="yellomain">
                      <div className="">
                        <p className="c3 nobox">15.5</p>
                        <span className="nopoint">25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* HT / FT div end  */}
          </div>

          {/* desktop view games-accodion-sect design end */}

          <div className="mobviewgameaccosec">
            <div className="gametitlediv">
              <h6 className="gamename">
                <Link className="iconbg" to="#">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Link>
                FK Rigas Futbola Skola
              </h6>
            </div>

            <div className="gameaccco-main">
              <Accordion
                defaultActiveKey={[
                  "00",
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "10",
                  "11",
                  "12",
                ]}
                alwaysOpen
              >
                <Accordion.Item eventKey="00">
                  <Accordion.Header>1st Half</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <div className="matchdetailsdiv">
                        <div className="row px-3 py-1 ">

                          <div className="col-xl-5">
                            <div className="coundowndiv">
                              <Countdown
                                date={Date.now() + 50000000}
                                renderer={renderer}
                              />
                            </div>
                            <div className="game-points-div">
                              <div className="row head">
                                <div className="col-4 p-0">
                                  <div className="d-flex bgg1">
                                    <div className="shirtdiv">
                                      <img
                                        className="shirt"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/Images/Exchange/livecenter/shirt1.png"
                                        }
                                      />
                                    </div>
                                    <div className="my-auto ms-1">
                                      <p className="gnme1">FK Rigas FutFuFutFu</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4 text-center midpoint p-0 ">
                                  <p>0:1</p>
                                </div>
                                <div className="col-4 p-0">
                                  <div className="d-flex bgg1">
                                    <div className="my-auto ms-1">
                                      <p className="gnme1">Bodoe/Glimt</p>
                                    </div>
                                    <div className="shirtdiv">
                                      <img
                                        className="shirt"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/Images/Exchange/livecenter/shirt2.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row scorediv">
                                <div className="col-2 colwidth p-0">
                                  <div className="points">
                                    <p className="no">4</p>
                                    <img
                                      className="iccsm"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Images/Exchange/livecenter/first-half/corner.png"
                                      }
                                    />
                                    <p className="no">4</p>
                                  </div>
                                </div>
                                <div className="col-2 colwidth p-0">
                                  <div className="points">
                                    <p className="no">4</p>
                                    <img
                                      className="iccsm"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Images/Exchange/livecenter/first-half/Rectangle1.png"
                                      }
                                    />
                                    <p className="no">4</p>
                                  </div>
                                </div>
                                <div className="col-2 colwidth p-0">
                                  <div className="points">
                                    <p className="no">4</p>
                                    <img
                                      className="iccsm"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Images/Exchange/livecenter/first-half/Rectangle2.png"
                                      }
                                    />
                                    <p className="no">4</p>
                                  </div>
                                </div>
                                <div className="col-2 colwidth p-0">
                                  <div className="points">
                                    <p className="no">4</p>
                                    <img
                                      className="iccsm"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Images/Exchange/livecenter/first-half/offsides.png"
                                      }
                                    />
                                    <p className="no">4</p>
                                  </div>
                                </div>
                                <div className="col-2 colwidth p-0">
                                  <div className="points">
                                    <p className="no">4</p>
                                    <img
                                      className="iccsm"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Images/Exchange/livecenter/first-half/Group.png"
                                      }
                                    />
                                    <p className="no">4</p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchscreendiv">
                                <img
                                  className="screen"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Images/Exchange/livecenter/football-game.png"
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-7">
                            <div className="matchdetdiv">
                              <Tab.Container
                                id="left-tabs-example"
                                defaultActiveKey="Heat"
                              >
                                <Nav variant="pills" className="">
                                  <Nav.Item>
                                    <Nav.Link eventKey="Heat">Heat Map</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="H2H">H2H</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="Stats">Stats</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="Standings">Standings</Nav.Link>
                                  </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                  <Tab.Pane eventKey="Heat">
                                    <div className="tab-cont">
                                      <div className="ground-div">
                                        <img
                                          className="groundimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Images/Exchange/livecenter/goals.png"
                                          }
                                        />

                                        <div className="goalsiccc">
                                          <div className="listdiv">
                                            <div className="icdiv">
                                              <img className="img" src={process.env.PUBLIC_URL + "/assets/Images/Exchange/icons/heatmap/one.png"} />
                                            </div>
                                            <div className="icdiv">
                                              <img className="img" src={process.env.PUBLIC_URL + "/assets/Images/Exchange/icons/heatmap/two.png"} />
                                            </div>
                                            <div className="icdiv">
                                              <img className="img" src={process.env.PUBLIC_URL + "/assets/Images/Exchange/icons/heatmap/three.png"} />
                                            </div>
                                            <div className="icdiv">
                                              <img className="img" src={process.env.PUBLIC_URL + "/assets/Images/Exchange/icons/heatmap/four.png"} />
                                            </div>
                                            <div className="icdiv">
                                              <img className="img" src={process.env.PUBLIC_URL + "/assets/Images/Exchange/icons/heatmap/five.png"} />
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="H2H">
                                    <div className="tab-cont">
                                      <Tab.Container
                                        id="left-tabs-example"
                                        defaultActiveKey="Total"
                                      >
                                        <Nav variant="pills" className="">
                                          <Nav.Item>
                                            <Nav.Link eventKey="Total">Total</Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item>
                                            <Nav.Link eventKey="Form">Form</Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item>
                                            <Nav.Link eventKey="Stats2">Stats</Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item>
                                            <Nav.Link eventKey="Matches">Matches</Nav.Link>
                                          </Nav.Item>
                                        </Nav>
                                        <Tab.Content>

                                          <Tab.Pane eventKey="Total">
                                            <div className="h2h-total mt-4">
                                              <div className="twovsgm">
                                                <div className="row">
                                                  <div className="col-4">
                                                    <div className="first">
                                                      <div className="imdiv">
                                                        <img
                                                          className="icon"
                                                          src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/Images/Exchange/livecenter/Group1.png"
                                                          }
                                                        />
                                                      </div>
                                                      <p className="contr">
                                                        FK Rigas Futbola Skola
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-4 text-center my-auto">
                                                    <p className="vs">VS</p>
                                                  </div>
                                                  <div className="col-4">
                                                    <div className="first">
                                                      <div className="imdiv">
                                                        <img
                                                          className="icon"
                                                          src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/Images/Exchange/livecenter/Group1.png"
                                                          }
                                                        />
                                                      </div>
                                                      <p className="contr">Bodoe/Glimt</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="h2h_holder range-bar">
                                                <div className="multi-progress-bar-section">
                                                  <div className="multi-progress-bar_holder">
                                                    <div className="progressbar-lable-holder">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <div className="label-text text-start">
                                                            <p>38 % - ESP</p>
                                                          </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                          <div className="label-text text-center">
                                                            <p>Shots on target</p>
                                                          </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                          <div className="label-text text-end">
                                                            <p>GER - 29 %</p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <MultiProgress
                                                      className="main_progress_bar_class"
                                                      elements={[
                                                        {
                                                          value: 20,
                                                          color: "#5D1628",
                                                          className: "progress_bar_css",
                                                          // // showPercentage: true,
                                                          fontSize: 7,
                                                          textColor: "white",
                                                          isBold: true,
                                                        },

                                                        {
                                                          value: 20,
                                                          color: "#E6304E",
                                                          className: "progress_bar_css",
                                                          // showPercentage: true,
                                                          fontSize: 7,
                                                          textColor: "white",
                                                          isBold: true,
                                                        },

                                                        {
                                                          value: 20,
                                                          color: "#F4C327",
                                                          className: "progress_bar_css",
                                                          // showPercentage: true,
                                                          fontSize: 7,
                                                          textColor: "white",
                                                          isBold: true,
                                                        },

                                                        {
                                                          value: 20,
                                                          color: "#14C2A1",
                                                          className: "progress_bar_css",
                                                          // showPercentage: true,
                                                          fontSize: 7,
                                                          textColor: "white",
                                                          isBold: true,
                                                        },

                                                        {
                                                          value: 20,
                                                          color: "#5D1628",
                                                          className: "progress_bar_css",
                                                          // showPercentage: true,
                                                          fontSize: 7,
                                                          textColor: "white",
                                                          isBold: true,
                                                        },
                                                      ]}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Tab.Pane>


                                          <Tab.Pane eventKey="Form">
                                            <div className="h2h-total mt-4">
                                              <div className="twovsgm">
                                                <div className="row">
                                                  <div className="col-4">
                                                    <div className="first">
                                                      <div className="imdiv">
                                                        <img
                                                          className="icon"
                                                          src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/Images/Exchange/livecenter/Group1.png"
                                                          }
                                                        />
                                                      </div>
                                                      <p className="contr">
                                                        FK Rigas Futbola Skola
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-4 text-center my-auto">
                                                    <p className="vs">VS</p>
                                                  </div>
                                                  <div className="col-4">
                                                    <div className="first">
                                                      <div className="imdiv">
                                                        <img
                                                          className="icon"
                                                          src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/Images/Exchange/livecenter/Group1.png"
                                                          }
                                                        />
                                                      </div>
                                                      <p className="contr">Bodoe/Glimt</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="homeairo">
                                                <div className="row">
                                                  <div className="col-5 ">
                                                    <div className="d-flex">
                                                      <p className="Ldiv">L</p>
                                                      <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/home.png'} />
                                                      <p className="list"><span>1:4</span>Shan United</p>
                                                    </div>
                                                    <div className="d-flex">
                                                      <p className="Ldiv">L</p>
                                                      <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/home.png'} />
                                                      <p className="list"><span>1:4</span>Shan United</p>
                                                    </div>
                                                    <div className="d-flex">
                                                      <p className="Ldiv">L</p>
                                                      <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/airoplane.png'} />
                                                      <p className="list"><span>1:4</span>Shan United</p>
                                                    </div>
                                                    <div className="d-flex">
                                                      <p className="Ldiv">L</p>
                                                      <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/airoplane.png'} />
                                                      <p className="list"><span>1:4</span>Shan United</p>
                                                    </div>
                                                  </div>
                                                  <div className="col-2 p-0 text-center">
                                                    <div className="bd-cent"></div>
                                                  </div>
                                                  <div className="col-5">
                                                    <div className="d-flex justify-content-end">
                                                      <p className="list"><span>1:4</span>Shan United</p>
                                                      <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/airoplane.png'} />

                                                      <p className="Ldiv">L</p>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                      <p className="list"><span>1:4</span>Shan United</p>
                                                      <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/home.png'} />

                                                      <p className="Ldiv">L</p>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                      <p className="list"><span>1:4</span>Shan United</p>
                                                      <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/home.png'} />

                                                      <p className="Ldiv">L</p>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                      <p className="list"><span>1:4</span>Shan United</p>
                                                      <img className="hmm" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/home.png'} />

                                                      <p className="Ldiv">L</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Tab.Pane>


                                          <Tab.Pane eventKey="Stats2">
                                            <div className="h2h-total mt-4">
                                              <div className="twovsgm">
                                                <div className="row">
                                                  <div className="col-4">
                                                    <div className="first">
                                                      <div className="imdiv">
                                                        <img
                                                          className="icon"
                                                          src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/Images/Exchange/livecenter/Group1.png"
                                                          }
                                                        />
                                                      </div>
                                                      <p className="contr">
                                                        FK Rigas Futbola Skola
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-4 text-center my-auto">
                                                    <p className="vs">VS</p>
                                                  </div>
                                                  <div className="col-4">
                                                    <div className="first">
                                                      <div className="imdiv">
                                                        <img
                                                          className="icon"
                                                          src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/Images/Exchange/livecenter/Group1.png"
                                                          }
                                                        />
                                                      </div>
                                                      <p className="contr">Bodoe/Glimt</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="avgp">
                                                <p>Averages</p>
                                              </div>


                                              <div className="avgoverflow">
                                                <div className="h2h_holder range-bar py-0">
                                                  <div className="multi-progress-bar-section">
                                                    <div className="multi-progress-bar_holder">
                                                      <div className="progressbar-lable-holder">
                                                        <div className="row">
                                                          <div className="col-md-2">
                                                            <div className="label-text text-start">
                                                              <p>0.75</p>
                                                            </div>
                                                          </div>

                                                          <div className="col-md-8">
                                                            <div className="label-text text-center">
                                                              <p>Average goals scored</p>
                                                            </div>
                                                          </div>

                                                          <div className="col-md-2">
                                                            <div className="label-text text-end">
                                                              <p>0.5</p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <MultiProgress
                                                        className="main_progress_bar_class"
                                                        elements={[
                                                          {
                                                            value: 20,
                                                            color: "#5D1628",
                                                            className: "progress_bar_css",
                                                            // // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#E6304E",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#F4C327",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#14C2A1",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#5D1628",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },
                                                        ]}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="h2h_holder range-bar py-0">
                                                  <div className="multi-progress-bar-section">
                                                    <div className="multi-progress-bar_holder">
                                                      <div className="progressbar-lable-holder">
                                                        <div className="row">
                                                          <div className="col-md-2">
                                                            <div className="label-text text-start">
                                                              <p>0.75</p>
                                                            </div>
                                                          </div>

                                                          <div className="col-md-8">
                                                            <div className="label-text text-center">
                                                              <p>Average goals scored</p>
                                                            </div>
                                                          </div>

                                                          <div className="col-md-2">
                                                            <div className="label-text text-end">
                                                              <p>0.5</p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <MultiProgress
                                                        className="main_progress_bar_class"
                                                        elements={[
                                                          {
                                                            value: 20,
                                                            color: "#5D1628",
                                                            className: "progress_bar_css",
                                                            // // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#E6304E",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#F4C327",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#14C2A1",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#5D1628",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },
                                                        ]}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="h2h_holder range-bar py-0">
                                                  <div className="multi-progress-bar-section">
                                                    <div className="multi-progress-bar_holder">
                                                      <div className="progressbar-lable-holder">
                                                        <div className="row">
                                                          <div className="col-md-2">
                                                            <div className="label-text text-start">
                                                              <p>0.75</p>
                                                            </div>
                                                          </div>

                                                          <div className="col-md-8">
                                                            <div className="label-text text-center">
                                                              <p>Average goals scored</p>
                                                            </div>
                                                          </div>

                                                          <div className="col-md-2">
                                                            <div className="label-text text-end">
                                                              <p>0.5</p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <MultiProgress
                                                        className="main_progress_bar_class"
                                                        elements={[
                                                          {
                                                            value: 20,
                                                            color: "#5D1628",
                                                            className: "progress_bar_css",
                                                            // // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#E6304E",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#F4C327",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#14C2A1",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },

                                                          {
                                                            value: 20,
                                                            color: "#5D1628",
                                                            className: "progress_bar_css",
                                                            // showPercentage: true,
                                                            fontSize: 7,
                                                            textColor: "white",
                                                            isBold: true,
                                                          },
                                                        ]}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </Tab.Pane>


                                          <Tab.Pane eventKey="Matches">
                                            <div className="h2h-total mt-4 py-2">
                                              <div className="fkrigasoevr">
                                                <div className="fk-rigas">

                                                  <div className="head">
                                                    <div className="last">
                                                      <p className="lmt">Last</p>
                                                      <p className="lmt">Matches</p>
                                                      <p className="big5">5</p>
                                                    </div>
                                                    <div className="name">
                                                      <p>FK Rigas Futbola Skola</p>
                                                    </div>
                                                  </div>

                                                  <div className="listdiv">
                                                    <div className="row">
                                                      <div className="col-8">
                                                        <p className="datep">28/07/2024 &nbsp; <span>National League</span></p>
                                                        <p className="fk"><img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group2.png'} /> &nbsp;FK Rigas Futbola Skola  &nbsp;<span>1 : 4</span></p>
                                                      </div>
                                                      <div className="col-4 text-end my-auto">
                                                        <p className="fk">FK Rigas  &nbsp;<img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} /></p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="listdiv">
                                                    <div className="row">
                                                      <div className="col-8">
                                                        <p className="datep">28/07/2024 &nbsp; <span>National League</span></p>
                                                        <p className="fk"><img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group2.png'} /> &nbsp;FK Rigas Futbola Skola  &nbsp;<span>1 : 4</span></p>
                                                      </div>
                                                      <div className="col-4 text-end my-auto">
                                                        <p className="fk">FK Rigas  &nbsp;<img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} /></p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="listdiv">
                                                    <div className="row">
                                                      <div className="col-8">
                                                        <p className="datep">28/07/2024 &nbsp; <span>National League</span></p>
                                                        <p className="fk"><img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group2.png'} /> &nbsp;FK Rigas Futbola Skola  &nbsp;<span>1 : 4</span></p>
                                                      </div>
                                                      <div className="col-4 text-end my-auto">
                                                        <p className="fk">FK Rigas  &nbsp;<img src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} /></p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                </div>

                                              </div>
                                            </div>
                                          </Tab.Pane>


                                        </Tab.Content>
                                      </Tab.Container>
                                    </div>
                                  </Tab.Pane>



                                  <Tab.Pane eventKey="Stats">
                                    <div className="tab-cont mt-4">
                                      <div className="stats-overflow">
                                        <div className="range-bar mb-3">
                                          <p className="kicks">Total shots</p>
                                          {/* <div className="range-bar-container">
                                <div className="percentage-text">{leftPercentage}%</div>
                                <div className="bar-container">
                                  <div className="bar left-bar" style={{ width: `${leftPercentage}%`, marginRight: "4px" }} />
                                  <div className="bar right-bar" style={{ width: `${rightPercentage}%` }} />
                                </div>
                                <div className="percentage-text">{rightPercentage}%</div>
                              </div> */}

                                          <div className="multi-progress-bar-section">
                                            <div className="multi-progress-bar_holder">
                                              <div className="progressbar-lable-holder">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <div className="label-text text-start">
                                                      <p>38 % - ESP</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                    <div className="label-text text-center">
                                                      <p>Shots on target</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                    <div className="label-text text-end">
                                                      <p>GER - 29 %</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <MultiProgress
                                                className="main_progress_bar_class"
                                                elements={[
                                                  {
                                                    value: 20,
                                                    color: "#5D1628",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#E6304E",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#F4C327",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#14C2A1",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#5D1628",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },
                                                ]}
                                              />
                                            </div>

                                            <div className="multi-progress-bar_holder">
                                              <div className="progressbar-lable-holder">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <div className="label-text text-start">
                                                      <p>38 % - ESP</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                    <div className="label-text text-center">
                                                      <p>Shots on target</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                    <div className="label-text text-end">
                                                      <p>GER - 29 %</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <MultiProgress
                                                className="main_progress_bar_class"
                                                elements={[
                                                  {
                                                    value: 20,
                                                    color: "#5D1628",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#E6304E",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#F4C327",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#14C2A1",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#5D1628",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },
                                                ]}
                                              />
                                            </div>

                                            <div className="multi-progress-bar_holder">
                                              <div className="progressbar-lable-holder">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <div className="label-text text-start">
                                                      <p>38 % - ESP</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                    <div className="label-text text-center">
                                                      <p>Shots on target</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                    <div className="label-text text-end">
                                                      <p>GER - 29 %</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <MultiProgress
                                                className="main_progress_bar_class"
                                                elements={[
                                                  {
                                                    value: 20,
                                                    color: "#5D1628",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#E6304E",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#F4C327",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#14C2A1",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#5D1628",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },
                                                ]}
                                              />
                                            </div>

                                            <div className="multi-progress-bar_holder">
                                              <div className="progressbar-lable-holder">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <div className="label-text text-start">
                                                      <p>38 % - ESP</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                    <div className="label-text text-center">
                                                      <p>Shots on target</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                    <div className="label-text text-end">
                                                      <p>GER - 29 %</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <MultiProgress
                                                className="main_progress_bar_class"
                                                elements={[
                                                  {
                                                    value: 20,
                                                    color: "#5D1628",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#E6304E",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#F4C327",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#14C2A1",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#5D1628",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },
                                                ]}
                                              />
                                            </div>

                                            <div className="multi-progress-bar_holder">
                                              <div className="progressbar-lable-holder">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <div className="label-text text-start">
                                                      <p>38 % - ESP</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                    <div className="label-text text-center">
                                                      <p>Shots on target</p>
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                    <div className="label-text text-end">
                                                      <p>GER - 29 %</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <MultiProgress
                                                className="main_progress_bar_class"
                                                elements={[
                                                  {
                                                    value: 20,
                                                    color: "#5D1628",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#E6304E",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#F4C327",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#14C2A1",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },

                                                  {
                                                    value: 20,
                                                    color: "#5D1628",
                                                    className: "progress_bar_css",
                                                    // showPercentage: true,
                                                    fontSize: 7,
                                                    textColor: "white",
                                                    isBold: true,
                                                  },
                                                ]}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>


                                  <Tab.Pane eventKey="Standings">
                                    <div className="tab-cont">
                                      <Tab.Container id="left-tabs-example" defaultActiveKey="Total1">
                                        <Nav variant="pills" className="">
                                          <Nav.Item>
                                            <Nav.Link eventKey="Total1">League</Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item>
                                            <Nav.Link eventKey="Form3">Home</Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item>
                                            <Nav.Link eventKey="Stats3">Away</Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item>
                                            <Nav.Link eventKey="Matches3">Form</Nav.Link>
                                          </Nav.Item>
                                        </Nav>

                                        <Tab.Content>

                                          <Tab.Pane eventKey="Total1">
                                            <div className="h2h-total py-0">
                                              <div class="avgp"><p>Regular Season</p></div>

                                              <div className="leaguetable table-responsive">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th>#</th>
                                                      <th>Team</th>
                                                      <th>MP</th>
                                                      <th>PTS</th>
                                                      <th>W</th>
                                                      <th>D</th>
                                                      <th>L</th>
                                                      <th>GD</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>1</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>2</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>3</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>4</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>5</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>6</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </Tab.Pane>

                                          <Tab.Pane eventKey="Form3">
                                            <div className="h2h-total py-0">
                                              <div class="avgp"><p>Regular Season</p></div>

                                              <div className="leaguetable table-responsive">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th>#</th>
                                                      <th>Team</th>
                                                      <th>MP</th>
                                                      <th>PTS</th>
                                                      <th>W</th>
                                                      <th>D</th>
                                                      <th>L</th>
                                                      <th>GD</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>1</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>2</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>3</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>4</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>5</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>6</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </Tab.Pane>

                                          <Tab.Pane eventKey="Stats3">
                                            <div className="h2h-total py-0">
                                              <div class="avgp"><p>Regular Season</p></div>

                                              <div className="leaguetable table-responsive">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th>#</th>
                                                      <th>Team</th>
                                                      <th>MP</th>
                                                      <th>PTS</th>
                                                      <th>W</th>
                                                      <th>D</th>
                                                      <th>L</th>
                                                      <th>GD</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>1</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>2</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>3</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>4</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>5</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                    <tr>
                                                      <td>6</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                      <td className="bgdrk"><span>5</span></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </Tab.Pane>

                                          <Tab.Pane eventKey="Matches3">
                                            <div className="h2h-total py-0">
                                              <div class="avgp"><p>Regular Season</p></div>

                                              <div className="leaguetable table-responsive">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th>#</th>
                                                      <th>Team</th>
                                                      <th>Form</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>1</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk">
                                                        <span className="red">5</span>
                                                        <span className="red">5</span>
                                                        <span className="yellow">5</span>
                                                        <span className="green">5</span>
                                                        <span className="green">5</span>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk">
                                                        <span className="red">5</span>
                                                        <span className="red">5</span>
                                                        <span className="yellow">5</span>
                                                        <span className="green">5</span>
                                                        <span className="green">5</span>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk">
                                                        <span className="red">5</span>
                                                        <span className="red">5</span>
                                                        <span className="yellow">5</span>
                                                        <span className="green">5</span>
                                                        <span className="green">5</span>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk">
                                                        <span className="red">5</span>
                                                        <span className="red">5</span>
                                                        <span className="yellow">5</span>
                                                        <span className="green">5</span>
                                                        <span className="green">5</span>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td><img className="lgimg" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/livecenter/Group1.png'} />Shan United</td>
                                                      <td className="bgdrk">
                                                        <span className="red">5</span>
                                                        <span className="red">5</span>
                                                        <span className="yellow">5</span>
                                                        <span className="green">5</span>
                                                        <span className="green">5</span>
                                                      </td>
                                                    </tr>

                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </Tab.Pane>

                                        </Tab.Content>
                                      </Tab.Container>
                                    </div>
                                  </Tab.Pane>

                                </Tab.Content>
                              </Tab.Container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="0">
                  <Accordion.Header>Match Odds</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>Bookmaker</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>Over under 15</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>Over under 25</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>1st Period Winner</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>2nd Period Winner</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header>Next Goal 2.0</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <Accordion.Header>Draw No Bet</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header>Both Teams to Score</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    Both Teams to Score 1st Half
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    Both Teams to Score 2nd Half
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                  <Accordion.Header>Goals Odd/Even</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center">Back</th>
                            <th className="text-center">Lay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="redpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <p className="mmtt">FK Rigas Futbola Skola</p>
                              <p className="greenpoint">-2700</p>
                            </th>
                            <th className="text-center">
                              <p className="backdiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                            <th className="text-center">
                              <p className="laydiv">17</p>
                              <p className="bcaccc">7</p>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="12">
                  <Accordion.Header>HT/FT</Accordion.Header>
                  <Accordion.Body>
                    <div className="game-table-div">
                      <table className="table table-responsive table-bordered">
                        <thead>
                          <tr>
                            <th>Max:50K</th>
                            <th className="text-center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <div className="row">
                                <div className="col-6 text-center">
                                  <p className="mmtt">1/1</p>
                                  <p className="redpoint">-2700</p>
                                </div>
                                <div className="col-6 text-center">
                                  <p className="backdiv">17</p>
                                  <p className="bcaccc">7</p>
                                </div>
                              </div>
                            </th>
                            <th className="text-center">
                              <div className="row">
                                <div className="col-6 text-center">
                                  <p className="mmtt">1/1</p>
                                  <p className="redpoint">-2700</p>
                                </div>
                                <div className="col-6 text-center">
                                  <p className="backdiv">17</p>
                                  <p className="bcaccc">7</p>
                                </div>
                              </div>
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <div className="row">
                                <div className="col-6 text-center">
                                  <p className="mmtt">1/1</p>
                                  <p className="greenpoint">-2700</p>
                                </div>
                                <div className="col-6 text-center">
                                  <p className="backdiv">17</p>
                                  <p className="bcaccc">7</p>
                                </div>
                              </div>
                            </th>
                            <th className="text-center">
                              <div className="row">
                                <div className="col-6 text-center">
                                  <p className="mmtt">1/1</p>
                                  <p className="greenpoint">-2700</p>
                                </div>
                                <div className="col-6 text-center">
                                  <p className="backdiv">17</p>
                                  <p className="bcaccc">7</p>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>
            </div>
          </div>


        </div>
      </section>
    </>
  );
};

export default Exchange_match_bet;
