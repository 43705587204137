import React from "react";
import "./Live_bet_sidebar.css";
import Live_Live_Center_tab_Section from "./Live_Live_Center/Live_Live_Center_tab_Section/Live_Live_Center_tab_Section";
import Live_Live_Center_my_Bet_Section from "./Live_Live_Center/Live_Live_Center_my_Bet_Section/Live_Live_Center_my_Bet_Section";

const Live_bet_sidebar = () => {
  return (
    <>
      <section className="ExchangeBetPlaceSidebar">
        <div className="min-heading">
          <p className="title">Live Centre</p>
          <div class="wrapper">
            <div class="corners corners--slanted"></div>
          </div>
        </div>

        <Live_Live_Center_tab_Section />

        <Live_Live_Center_my_Bet_Section />
      </section>
    </>
  );
};

export default Live_bet_sidebar;
