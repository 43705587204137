import React, { useContext, useEffect, useState } from "react";
import "./Banner.css";
import { Button } from "react-bootstrap";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { getHomeBanner } from "../../../utils/apis/home";
import { Context } from "../../../utils/context";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

function Banner() {
  const { IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getHomeBanner();
    if (response?.success) {
      setData(response?.data);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [])

  return (
    <>
      <section className="Banner">
        <Swiper
          className="banner-swiper mySwiper"
          modules={[Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          loop={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {data && data?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="banner-holder">
                <div className="banner-img-holder">
                  <img
                    className="banner-img"
                    src={IMG_URL + item.image}
                    alt="banner-img"
                  />
                </div>

                <div className="banner-content-holder">
                  <div className="container">
                    <div className="text-holder">
                      <h4>{item?.sub_title}</h4>
                      <h2>{item?.name}</h2>
                      <p>
                        {item?.description}
                      </p>
                    </div>

                    <div className="btn-holder">
                      <Button className="download_btn">Get Started Now!</Button>
                    </div>
                  </div>
                </div>

                <div className="overlay-holder"></div>
              </div>
            </SwiperSlide>
          ))}


          {/* <SwiperSlide>
            <div className="banner-holder">
              <div className="banner-img-holder">
                <img
                  className="banner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/banner-img-1.png"
                  }
                  alt="banner-img"
                />
              </div>

              <div className="left-bottom-corner-img-holder">
                <img
                  className="left-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/left-bottom-corner.png"
                  }
                  alt="left-bottom-corner-img"
                />
              </div>

              <div className="right-bottom-corner-img-holder">
                <img
                  className="right-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/right-bottom-corner.png"
                  }
                  alt="right-bottom-corner-img"
                />
              </div>
              <div className="banner-content-holder">
                <div className="container">
                  <div className="text-holder">
                    <h4>Bet & Win Today!</h4>
                    <h2>Warri Sports Bets Peer 2 Peer</h2>
                    <p>
                      The fastest, easiest way to bet on sports. <br /> Choose
                      on which team to bet and win
                    </p>
                  </div>

                  <div className="btn-holder">
                    <Button className="download_btn">Get Started Now!</Button>
                  </div>
                </div>
              </div>

              <div className="overlay-holder"></div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="banner-holder">
              <div className="banner-img-holder">
                <img
                  className="banner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/banner-img-1.png"
                  }
                  alt="banner-img"
                />
              </div>

              <div className="left-bottom-corner-img-holder">
                <img
                  className="left-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/left-bottom-corner.png"
                  }
                  alt="left-bottom-corner-img"
                />
              </div>

              <div className="right-bottom-corner-img-holder">
                <img
                  className="right-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/right-bottom-corner.png"
                  }
                  alt="right-bottom-corner-img"
                />
              </div>
              <div className="banner-content-holder">
                <div className="container">
                  <div className="text-holder">
                    <h4>Bet & Win Today!</h4>
                    <h2>Warri Sports Bets Peer 2 Peer</h2>
                    <p>
                      The fastest, easiest way to bet on sports. <br /> Choose
                      on which team to bet and win
                    </p>
                  </div>

                  <div className="btn-holder">
                    <Button className="download_btn">Get Started Now!</Button>
                  </div>
                </div>
              </div>

              <div className="overlay-holder"></div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="banner-holder">
              <div className="banner-img-holder">
                <img
                  className="banner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/banner-img-1.png"
                  }
                  alt="banner-img"
                />
              </div>

              <div className="left-bottom-corner-img-holder">
                <img
                  className="left-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/left-bottom-corner.png"
                  }
                  alt="left-bottom-corner-img"
                />
              </div>

              <div className="right-bottom-corner-img-holder">
                <img
                  className="right-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/right-bottom-corner.png"
                  }
                  alt="right-bottom-corner-img"
                />
              </div>
              <div className="banner-content-holder">
                <div className="container">
                  <div className="text-holder">
                    <h4>Bet & Win Today!</h4>
                    <h2>Warri Sports Bets Peer 2 Peer</h2>
                    <p>
                      The fastest, easiest way to bet on sports. <br /> Choose
                      on which team to bet and win
                    </p>
                  </div>

                  <div className="btn-holder">
                    <Button className="download_btn">Get Started Now!</Button>
                  </div>
                </div>
              </div>

              <div className="overlay-holder"></div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="banner-holder">
              <div className="banner-img-holder">
                <img
                  className="banner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/banner-img-1.png"
                  }
                  alt="banner-img"
                />
              </div>

              <div className="left-bottom-corner-img-holder">
                <img
                  className="left-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/left-bottom-corner.png"
                  }
                  alt="left-bottom-corner-img"
                />
              </div>

              <div className="right-bottom-corner-img-holder">
                <img
                  className="right-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/right-bottom-corner.png"
                  }
                  alt="right-bottom-corner-img"
                />
              </div>
              <div className="banner-content-holder">
                <div className="container">
                  <div className="text-holder">
                    <h4>Bet & Win Today!</h4>
                    <h2>Warri Sports Bets Peer 2 Peer</h2>
                    <p>
                      The fastest, easiest way to bet on sports. <br /> Choose
                      on which team to bet and win
                    </p>
                  </div>

                  <div className="btn-holder">
                    <Button className="download_btn">Get Started Now!</Button>
                  </div>
                </div>
              </div>

              <div className="overlay-holder"></div>
            </div>
          </SwiperSlide> */}

        </Swiper>
      </section>
    </>
  );
}

export default Banner;
