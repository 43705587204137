import React from "react";
import "./Overview.css";
import Overview_card from "./Overview_card/Overview_card";

function Overview({
  allLive,
  seteventDetailsId,
  seteventDetails,
  setActive,
  getValues,
  setValue,
}) {



  return (
    <>
      <section className="Overview">
        {getValues("all_live")?.eventCount > 0 ? (
          getValues("all_live.events")?.map((event, index) => {
            return (
              <div className="Overview_card_content_holder mb-4" key={index}>
                <Overview_card
                  event={event}
                  getValues={getValues}
                  setValue={setValue}
                  // seteventDetailsId={seteventDetailsId}
                  // seteventDetails={seteventDetails}
                  setActive={setActive}
                />
              </div>
            );
          })
        ) : (
          <h1>No Live Match Going On</h1>
        )}

        {/* <div className="Overview_card_content_holder mb-4">
          <Overview_card />
        </div>

        <div className="Overview_card_content_holder mb-4">
          <Overview_card />
        </div>

        <div className="Overview_card_content_holder mb-4">
          <Overview_card />
        </div>

        <div className="Overview_card_content_holder mb-4">
          <Overview_card />
        </div> */}
      </section>
    </>
  );
}

export default Overview;
