import React, { useEffect, useRef, useState } from "react";
import { Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFutbol } from "@fortawesome/free-solid-svg-icons";
import Live_GraphTab from "../../Live_GraphTab";
import Live_Probability from "../../Live_Probability";
import Live_VoiceTab from "../../Live_VoiceTab";

function Live_Live_Center_tab_Section() {
  const items = [
    { key: "FC Midtjylland", label: "FK Rigas Futbola Skola" },
    { key: "Another action", label: "FC Midtjylland" },
    { key: "Sparta Prague", label: "Sparta Prague" },
    { key: "FC Midtjylland", label: "FK Rigas Futbola Skola" },
    { key: "Another action", label: "FC Midtjylland" },
    { key: "Sparta Prague", label: "Sparta Prague" },
    { key: "FC Midtjylland", label: "FK Rigas Futbola Skola" },
    { key: "Another action", label: "FC Midtjylland" },
    { key: "Sparta Prague", label: "Sparta Prague" },
  ];

  const [selectedItem, setSelectedItem] = useState(items[0]);

  const handleSelect = (eventKey) => {
    const item = items.find((i) => i.key === eventKey);
    setSelectedItem(item);
  };

  const vidRef = useRef();

  useEffect(() => {
    vidRef.current.play();
  }, []);
  return (
    <>
      <section className="Exchange_Live_Center_tab_Section">
        <div className="Tab-section">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="main-sec me-0 ms-0">
              <Col xxl={4} xl={4} lg={12} md={4}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Exchange/livecenter/comp1.png"
                        }
                        className="logo"
                        alt="Component 1"
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Exchange/livecenter/comp2.png"
                        }
                        className="logo"
                        alt="Component 2"
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Exchange/livecenter/comp3.png"
                        }
                        className="logo"
                        alt="Component 3"
                      />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xxl={8} xl={8} lg={12} md={8}>
                <div className="dropdown-main">
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="custom-dropdown-toggle"
                    >
                      <div className="names-drop">
                        <FontAwesomeIcon icon={faFutbol} />
                        <p className="text">{selectedItem.label}</p>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="scrollbar" id="style-3">
                        {items.map((item) => (
                          <Dropdown.Item key={item.key} eventKey={item.key}>
                            <div className="names-drop">
                              <FontAwesomeIcon icon={faFutbol} />
                              <p className="text">{item.label}</p>
                            </div>
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="main-video">
                  <video
                    src="/assets/Images/Exchange/livecenter/vedio.mp4"
                    ref={vidRef}
                    muted
                    loop
                    className="video-class-grid"
                    controls
                  />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                <div className="main-video">
                  <video
                    src="/assets/Images/Exchange/livecenter/vedio.mp4"
                    ref={vidRef}
                    muted
                    loop
                    className="video-class-grid"
                    controls
                  />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="third">
                <div className="graph">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Nav variant="pills" className="">
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/Icon-ionic-md-stats.png"
                            }
                            className="logo"
                            alt="Component 1"
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/heat-to-head.png"
                            }
                            className="logo"
                            alt="Component 1"
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/Exchange/livecenter/Icon-awesome-microphone.png"
                            }
                            className="logo"
                            alt="Component 1"
                          />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Live_GraphTab />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Live_Probability />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Live_VoiceTab />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
}

export default Live_Live_Center_tab_Section;
