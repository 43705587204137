import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
} from "../../api";

export const getSocialMedia = async () => {
    try {
        return await getData(`/without-login/app-setup/social-media`);
    } catch (error) {
        console.log(error);
    }
};
