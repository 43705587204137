import React from "react";
import { Modal } from "react-bootstrap";
import "./Success_Modal.css";
import Successfull_Lottie from "../../../Exchange/Animation/Successfull_Lottie/Successfull_Lottie";

function Success_Modal({ text, ...props }) {
  return (
    <>
      <Modal
        {...props}
        className="Success_Modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-content">
            <Successfull_Lottie />

            <div className="text-holder">
              <p className="text">{text}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Success_Modal;
