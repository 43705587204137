import React from "react";
import "./Exchange_bet_sidebar.css";

import Exchange_Live_Center_tab_Section from "./Exchange_Live_Center/Exchange_Live_Center_tab_Section/Exchange_Live_Center_tab_Section";
import Exchange_Live_Center_my_Bet_Section from "./Exchange_Live_Center/Exchange_Live_Center_my_Bet_Section/Exchange_Live_Center_my_Bet_Section";

const Exchange_bet_sidebar = () => {


  
    
  
    return (
        <>
            <section className="ExchangeBetPlaceSidebar">
                <div className="min-heading">
                    <p className="title">Live Centre</p>
                    <div class="wrapper">
                        <div class="corners corners--slanted"></div>
                    </div>
                </div>

                <Exchange_Live_Center_tab_Section />

                <Exchange_Live_Center_my_Bet_Section />
                
            </section>
        </>
    );
}


export default Exchange_bet_sidebar
