import React from 'react'
import './EX_right_mybet_sidebar.css'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Exchange_Live_Center_my_Bet_Section from '../../../../Exchange-layout/Exchange-bet-sidebar/Exchange_Live_Center/Exchange_Live_Center_my_Bet_Section/Exchange_Live_Center_my_Bet_Section';

const EX_right_mybet_sidebar = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            {['bottom'].map((placement, idx) => (
                <Offcanvas className="EX_right_mybet_sidebar" show={show} onHide={handleClose} {...props} key={idx} placement={placement} name={placement}>
                    <Offcanvas.Header closeButton>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='ExchangeBetPlaceSidebar'>
                            <Exchange_Live_Center_my_Bet_Section />
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            ))}
        </>
    )
}

export default EX_right_mybet_sidebar