import React, { useState } from "react";

import "./exchangeprofile.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

const BetHistory = () => {
  const [show, setShow] = React.useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    <>
      <section className="exchangeprofilemain-sec">
        <div className="secttiill">
          <div className="titlediv">
            <p className="title">Bet History</p>
          </div>
        </div>

        <div className="tablemain-div">
          <div className="row">
            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <Form.Control type="text" placeholder="Football"/>
                  
                
              </div>
            </div>

            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <Form.Select aria-label="Default select example">
                  <option>Matched</option>
                  <option value="1">Deleted</option>
                </Form.Select>
              </div>
            </div>

            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="MM/DD/YYYY"
                />

                <div className="calender-icon_holder">
                  <FontAwesomeIcon
                    className="calender-icon"
                    icon={faCalendarDays}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="MM/DD/YYYY"
                />

                <div className="calender-icon_holder">
                  <FontAwesomeIcon
                    className="calender-icon"
                    icon={faCalendarDays}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <button className="submitbtnn">Submit</button>
              </div>
            </div>
          </div>

           <div className="table-div colortable">
                        <div className="table-responsive">
                            <table className="table table-bordered striped">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Event Name</th>
                                        <th>Nation</th>
                                        <th>Event Type</th>
                                        <th>Market Name</th>
                                        <th>Side</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                        <th>Place Date</th>
                                        <th>Match Date</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>

                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>

                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>

                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>

                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>

                                    <tr>
                                        <td>Date</td>
                                        <td>Sr.No</td>
                                        <td>Credit</td>
                                        <td>Debit</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                        <td>Balance</td>
                                        <td>Remark</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
        </div>
      </section>
    </>
  );
};

export default BetHistory;
