import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Exchange_home from "../Exchange-components/Exchange-home/Exchange_home";
import Exchange_match_bet from "../Exchange-components/Exchange-match-bet/Exchange_match_bet";
import AccountStatement from "../Exchange-components/ExchangeProfile/AccountStatement";
import BetHistory from "../Exchange-components/ExchangeProfile/BetHistory";
import ChangePassword from "../Exchange-components/ExchangeProfile/ChangePassword";
import SecureAuth from "../Exchange-components/ExchangeProfile/SecureAuth";
import UnsetteledBet from "../Exchange-components/ExchangeProfile/UnsetteledBet";
import ProfitLoss from "../Exchange-components/ExchangeProfile/ProfitLoss";
import ButtonValues from "../Exchange-components/ExchangeProfile/ButtonValues";
import ExchangeMobProfile from "../Exchange-components/ExchangeProfile/ExchangeMobProfile";

const Exchange_routes = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Routes>
        <Route path="/home" element={<Exchange_home />} />
        <Route path="/match-bet" element={<Exchange_match_bet />} />
        <Route path="/account-statement" element={<AccountStatement />} />
        <Route path="/bet-history" element={<BetHistory />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/security-auth-verification" element={<SecureAuth />} />
        <Route path="/set-button-values" element={<ButtonValues />} />
        <Route path="/unsetteled-bet" element={<UnsetteledBet />} />
        <Route path="/profit-loss-report" element={<ProfitLoss />} />
        <Route path="/profile" element={<ExchangeMobProfile />} />
      </Routes>
    </>
  );
};

export default Exchange_routes;
