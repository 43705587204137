import React from "react";
import Banner from "./Banner/Banner";
import Home_Counter from "./Home_Counter/Home_Counter";
import About_Sports from "./About_Sports/About_Sports";
import Home_Sports from "./Home_Sports/Home_Sports";
import Home_Game_Tabs from "./Home_Game_Tabs/Home_Game_Tabs";

function Home() {
  return (
    <>
      <Banner />
      <Home_Counter />
      <About_Sports />
      <Home_Sports />
      <Home_Game_Tabs/>
    </>
  );
}

export default Home;
