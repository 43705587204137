import React, { useContext, useState } from "react";
import "./Header.css";
import { faBars, faMagnifyingGlass, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Login_Modal from "../Authentication/Login_Modal/Login_Modal";
import Sign_Up_Modal from "../Authentication/Sign_Up_Modal/Sign_Up_Modal";
import ExposureModal from "../Exposure/ExposureModal/ExposureModal";
import Rules from "../Rules/Rules";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Context } from "../../utils/context";


function Header() {
  const {
    signin,
    setSignin,
    Cookies,
    userdata,
  } = useContext(Context);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowExpo, setModalShowExpo] = React.useState(false);
  const [signUpModalShow, setSignUpModalShow] = React.useState(false);
  const [modalShowrules, setModalShowRules] = React.useState(false);

  // State to track the visibility of the search input
  const [showSearch, setShowSearch] = useState(false);

  // Toggle function to show/hide the search bar
  const toggleSearchBar = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = async () => {
    Cookies.remove("betzone_security");
    setSignin("false")
  };

  console.log("userdata", userdata);


  return (
    <>
      <section className="Header">
        <div className="container-fluid">


          <div className="desktop-header">
            <Navbar expand="lg" className="header_holder">
              <Navbar.Brand className="desktop-view-logo" as={Link} to={"/"}>
                <div className="logo-holder">
                  <img className="header_logo" src={process.env.PUBLIC_URL + "/assets/Images/Header/betzone-logo.png"} alt="header_logo" />
                </div>
              </Navbar.Brand>

              <Navbar.Toggle className="deskbar" aria-controls="navbarScroll" />
              <FontAwesomeIcon type="button" onClick={handleShow} className="mobbar" icon={faBars} />

              <div className="balancediv">
                <p className="banp">Balance: <span>276116.00</span></p>
                <p className="banp" onClick={() => setModalShowExpo(true)}>Exposure: <span>20</span></p>
              </div>

              <Navbar.Brand className="mobile-view-logo" href="/">
                <div className="logo-holder">
                  <img className="header_logo" src={process.env.PUBLIC_URL + "/assets/Images/Header/betzone-logo.png"} alt="header_logo" />
                </div>
              </Navbar.Brand>

              <Navbar.Collapse id="navbarScroll">
                <Nav className="navbar_holder" navbarScroll>
                  <Nav.Link
                    as={NavLink}
                    to={"/"}
                    activeclassname="active"
                    className="nav-link"
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link as={NavLink} to={"/live"} activeclassname="active">
                    Live
                  </Nav.Link>
                  <Nav.Link as={NavLink} to={"/virtual"} activeclassname="active">
                    Virtual
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to={"/exchange/home"}
                    activeclassname="active"
                  >
                    Exchange
                  </Nav.Link>
                </Nav>

                <div className="desktop-view">
                  <div className="header_btn_holder ">
                    {!showSearch ? (
                      <div className="btn-holder search_holder">
                        <FontAwesomeIcon
                          className="search_icon search_icon_black"
                          icon={faMagnifyingGlass}
                          onClick={toggleSearchBar}
                        />
                      </div>
                    ) : (
                      <div className="btn-holder search_holder">
                        <InputGroup className="form_group_holder">
                          <Form.Control
                            className="none_form_control full_form_control"
                            placeholder="All Events"
                            aria-label="AllEvents"
                          />
                          <InputGroup.Text>
                            <FontAwesomeIcon
                              className="search_icon"
                              icon={faMagnifyingGlass}
                              onClick={toggleSearchBar}
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    )}

                    <Nav className="navbar_holder">
                      <Nav.Link className="header-text" href="#action1" onClick={() => setModalShowRules(true)}>
                        Rules
                      </Nav.Link>
                      {signin ?
                        <div className="heading-text-holder">
                          <Nav.Link className="header-text">
                            <p>
                              Balance: <span>276116.00</span>
                            </p>
                          </Nav.Link>
                          <Nav.Link className="header-text text-decoration-holder">
                            <p onClick={() => setModalShowExpo(true)}>
                              Exposure: <span>0</span>
                            </p>
                          </Nav.Link>
                        </div>
                        : <></>}
                    </Nav>

                    {signin ?
                      <></>
                      :
                      <>
                        <div className="btn-holder login_btn_holder">
                          <Button
                            className="btn_text login_btn"
                            onClick={() => setModalShow(true)}
                          >
                            Log In
                          </Button>
                        </div>
                        <div className="btn-holder signup_btn_holder">
                          <Button
                            className="btn_text signup_btn"
                            onClick={() => setSignUpModalShow(true)}
                          >
                            Sign Up
                          </Button>
                        </div></>
                    }

                    {signin ?
                      <div className="btn-holder">
                        <Dropdown className="dropdown_holder">
                          <Dropdown.Toggle
                            className={"btn_text drop_btn"}
                            id="dropdown-basic"
                          >
                            {userdata?.first_name}   {userdata?.last_name}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div className="main_menu_holder">
                              <Dropdown.Item
                                as={Link}
                                to={"/exchange/account-statement"}
                              >
                                Account Statement
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to={"/exchange/profit-loss-report"}
                              >
                                Profit Loss Report
                              </Dropdown.Item>
                              <Dropdown.Item as={Link} to={"/exchange/bet-history"}>
                                Bet History
                              </Dropdown.Item>

                              <Dropdown.Item
                                as={Link}
                                to={"/exchange/unsetteled-bet"}
                              >
                                Unsetteled Bet
                              </Dropdown.Item>

                              <Dropdown.Item
                                as={Link}
                                to={"/exchange/set-button-values"}
                              >
                                Set Button Values
                              </Dropdown.Item>

                              <Dropdown.Item
                                as={Link}
                                to={"/exchange/security-auth-verification"}
                              >
                                Security Auth Verification
                              </Dropdown.Item>

                              <Dropdown.Item
                                className="last_drop_item"
                                as={Link}
                                to={"/exchange/change-password"}
                              >
                                Change Password
                              </Dropdown.Item>
                            </div>
                            <div className="sign_out_btn_holder">
                              <Dropdown.Item
                                className="sign_out_btn"
                                as={Link}
                                onClick={() => handleLogout()}
                              >
                                Sign Out
                              </Dropdown.Item>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      :
                      <></>
                    }

                  </div>
                </div>
              </Navbar.Collapse>
            </Navbar>
          </div>

          <div className="mobile-header">
            <div className="mobsearchdiv">
              <div className="row">
                <div className="col-10 pe-0">
                  <Form.Control type="search" placeholder="Search here..." />
                </div>
                <div className="col-2 text-end">
                  <div className="btn-holder login_btn_holder">
                    <Button
                      className="btn_text login_btn"
                      onClick={() => setModalShow(true)}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>



      <Offcanvas className="mobviewheadsidebar" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><img className="header_logo" src={process.env.PUBLIC_URL + "/assets/Images/Header/betzone-logo.png"} alt="header_logo" /></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="sidebar-menus">
            <div className="menu-div">
              <Link to="/" onClick={handleClose}>Home</Link>
            </div>
            <div className="menu-div">
              <Link to="/live" onClick={handleClose}>Live</Link>
            </div>
            <div className="menu-div">
              <Link to="/virtual" onClick={handleClose}>Virtual</Link>
            </div>
            <div className="menu-div">
              <Link to="/exchange/home" onClick={handleClose}>Exchange</Link>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>



      <Login_Modal show={modalShow} onHide={() => setModalShow(false)} loginModal={() => setModalShow(true)} />

      <Sign_Up_Modal
        show={signUpModalShow}
        onHide={() => setSignUpModalShow(false)}
      />

      <ExposureModal
        show={modalShowExpo}
        onHide={() => setModalShowExpo(false)}
      />

      <Rules show={modalShowrules} onHide={() => setModalShowRules(false)} />
    </>
  );
}

export default Header;
