import React from "react";
import "./About_Sports.css";

function About_Sports() {
  return (
    <>
      <section className="About_Sports">
        <div className="container">
          <div className="text-holder">
            <h2>About Warri Sports</h2>
            <p>
              Welcome to Warri Sports, the world's leading online betting site
              and home to some of the best gaming products on the web, including
              world-class online betting games. We are fully licensed and
              regulated in various jurisdictions, as outlined in our Terms and
              Conditions, and Betzone9ja operates strictly within these
              regulations.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default About_Sports;
