import React from "react";
import {Col, Row} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShirt} from "@fortawesome/free-solid-svg-icons";
const Exchange_VoiceTab = () => {
    return (
        <>
            <section className="VoiceTab">
                <p className="title mt-1">TIMELINE</p>
                <Accordion className="mt-2">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className="main-text">
                                <p className="text">2nd Half</p>
                                <p className="text">1 - 0</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="end-game">
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/Images/Exchange/livecenter/shoes.png"}
                                    className="logo me-2"
                                />
                                <p className="text">End of Game</p>
                            </div>
                            <div className="full-time">
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/Images/Exchange/livecenter/shoes.png"}
                                    className="logo me-2"
                                />
                                <p className="text">Full Time</p>
                            </div>
                            <div className="scroller">
                                <div className="game-main">
                                    <Row className="score">
                                        <Col xxl={3} xl={4} xs={4}>
                                            <p className="game-text">2'</p>
                                            <p className="game-text">Throw-in</p>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <div className="team-t-shirt">
                                                <FontAwesomeIcon icon={faShirt} className="t-shirt-blue" />
                                                <p className="game-text bold">ANT</p>
                                            </div>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/Images/Exchange/livecenter/arrow.png"
                                                }
                                                className="logo me-2"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="game-main">
                                    <Row className="score">
                                        <Col xxl={4} xl={4} xs={4}>
                                            <p className="game-text">3'</p>
                                            <p className="game-text">Corner Kick</p>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <div className="team-t-shirt">
                                                <FontAwesomeIcon icon={faShirt} className="t-shirt-green" />
                                                <p className="game-text bold">RBL</p>
                                            </div>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/Images/Exchange/livecenter/angle.png"
                                                }
                                                className="logo me-2"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="game-main">
                                    <Row className="score">
                                        <Col xxl={4} xl={4} xs={4}>
                                            <p className="game-text">3'</p>
                                            <p className="game-text">Corner Kick</p>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <div className="team-t-shirt">
                                                <FontAwesomeIcon icon={faShirt} className="t-shirt-green" />
                                                <p className="game-text bold">RBL</p>
                                            </div>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/Images/Exchange/livecenter/angle.png"
                                                }
                                                className="logo me-2"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="game-main">
                                    <Row className="score">
                                        <Col xxl={4} xl={4} xs={4}>
                                            <p className="game-text">2'</p>
                                            <p className="game-text">Throw-in</p>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <div className="team-t-shirt">
                                                <FontAwesomeIcon icon={faShirt} className="t-shirt-blue" />
                                                <p className="game-text bold">ANT</p>
                                            </div>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/Images/Exchange/livecenter/arrow.png"
                                                }
                                                className="logo me-2"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="full-time">
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/Images/Exchange/livecenter/shoes.png"}
                                    className="logo me-2"
                                />
                                <p className="text">2nd Half</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1" className="mt-1">
                        <Accordion.Header>
                            <div className="main-text">
                                <p className="text">1st Half</p>
                                <p className="text">1 - 0</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="scroller">
                                <div className="game-main">
                                    <Row className="score">
                                        <Col xxl={4} xl={4} xs={4}>
                                            <p className="game-text">2'</p>
                                            <p className="game-text">Throw-in</p>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <div className="team-t-shirt">
                                                <FontAwesomeIcon icon={faShirt} className="t-shirt-blue" />
                                                <p className="game-text bold">ANT</p>
                                            </div>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/Images/Exchange/livecenter/arrow.png"
                                                }
                                                className="logo me-2"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="game-main">
                                    <Row className="score">
                                        <Col xxl={4} xl={4} xs={4}>
                                            <p className="game-text">3'</p>
                                            <p className="game-text">Corner Kick</p>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <div className="team-t-shirt">
                                                <FontAwesomeIcon icon={faShirt} className="t-shirt-green" />
                                                <p className="game-text bold">RBL</p>
                                            </div>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/Images/Exchange/livecenter/angle.png"
                                                }
                                                className="logo me-2"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="game-main">
                                    <Row className="score">
                                        <Col xxl={4} xl={4} xs={4}>
                                            <p className="game-text">3'</p>
                                            <p className="game-text">Corner Kick</p>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <div className="team-t-shirt">
                                                <FontAwesomeIcon icon={faShirt} className="t-shirt-green" />
                                                <p className="game-text bold">RBL</p>
                                            </div>
                                        </Col>
                                        <Col xxl={4} xl={4} xs={4}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/Images/Exchange/livecenter/angle.png"
                                                }
                                                className="logo me-2"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="game-main">
                                    <Row className="score">
                                        <Col xxl={3} xl={3} xs={4}>
                                            <p className="game-text">2'</p>
                                            <p className="game-text">Throw-in</p>
                                        </Col>
                                        <Col xxl={2} xl={2} xs={4}>
                                            <div className="team-t-shirt">
                                                <FontAwesomeIcon icon={faShirt} className="t-shirt-blue" />
                                                <p className="game-text bold">ANT</p>
                                            </div>
                                        </Col>
                                        <Col xxl={2} xl={2} xs={4}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/Images/Exchange/livecenter/arrow.png"
                                                }
                                                className="logo me-2"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
               
            </section>
        </>
    );
};

export default Exchange_VoiceTab;
