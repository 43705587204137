import React from "react";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
// import "rsuite/dist/rsuite.min.css";
import { Progress } from "rsuite";
const ProgressBar = ({ percent, color, vertical }) => {
    const progressStyle = {
        height: vertical ? `${percent}%` : "100%",
        width: vertical ? "100%" : `${percent}%`,
    };

    return (
        <div className={`progress-bar ${color}`}>
            <div className="progress-fill" style={progressStyle} />
        </div>
    );
};
const Exchange_Probability = () => {
    const leftPercentage = 90;
    const rightPercentage = 10;

    const [percent, setPercent] = React.useState(30);

    const decline = () => {
        const value = Math.max(percent - 10, 0);
        setPercent(value);
    };

    const increase = () => {
        const value = Math.min(percent + 10, 100);
        setPercent(value);
    };

    const status = percent === 100 ? "success" : null;
    const color = percent === 100 ? "#52c41a" : "#3385ff";

    return (
        <>
            <section className="GraphTab">
                <Swiper
                    pagination={{
                        type: "fraction",
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <p className="card-no main">HEAD TO HEAD</p>

                        <div className="range-bar">
                            <p className="kicks">WIN PROBABILITY</p>
                            <p className="withdraw">8% DRAW</p>
                            <div className="range-bar-container">
                                {/* <Progress.Line vertical percent={percent} strokeColor={color} status={status} /> */}
                                <div className="percentage-text blue">
                                    {leftPercentage}% <p>FCM</p>
                                </div>
                                <div className="bar-container">
                                    <div
                                        className="bar left-bar"
                                        style={{ width: `${leftPercentage}%`, marginRight: "4px" }}
                                    />
                                    <div className="bar right-bar" style={{ width: `${rightPercentage}%` }} />
                                </div>
                                <div className="percentage-text green">
                                    {rightPercentage}%<p>VIL</p>{" "}
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <p className="card-no main">HEAD TO HEAD</p>
                        <Row>
                            <Col xxl={4} xs={4}  className="mt-4">
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <div className="main-box">
                                            <div className="box yellow">
                                                <p className="wicket">D</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="name">INT</p>
                                    </Col>
                                </Row>
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <div className="main-box">
                                            <div className="box green">
                                                <p className="wicket">L</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="name">DEL</p>
                                    </Col>
                                </Row>
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <div className="main-box">
                                            <div className="box blue">
                                                <p className="wicket">W</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="name">LAJ</p>
                                    </Col>
                                </Row>
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <div className="main-box">
                                            <div className="box yellow">
                                                <p className="wicket">D</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="name">INT</p>
                                    </Col>
                                </Row>
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <div className="main-box">
                                            <div className="box yellow">
                                                <p className="wicket">D</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="name">REAL</p>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xxl={4} xs={4} >
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4} className="mt-4">
                                        <p className="point">1:1</p>
                                        <p className="point">1:3</p>
                                        <p className="point">1:1</p>
                                        <p className="point">1:0</p>
                                        <p className="point">1:2</p>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="point">FORM</p>
                                        <div className="progress-team">
                                            <div className="progress-wrapper">
                                                <div className="blue-team">
                                                    <ProgressBar percent={40} color="blue" vertical={true} />
                                                </div>
                                                <p className="percentage-text blue">40%</p>
                                            </div>
                                            <div className="progress-wrapper">
                                                <div className="green-team">
                                                    <ProgressBar percent={47} color="green" vertical={true} />
                                                </div>
                                                <p className="percentage-text green">47%</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4} className="mt-4">
                                        <p className="point">3:0</p>
                                        <p className="point">4:0</p>
                                        <p className="point">0:4</p>
                                        <p className="point">2:1</p>
                                        <p className="point">0:0</p>
                                    </Col>
                                </Row>
                                <div className="Points-graph"></div>
                            </Col>

                            <Col xxl={4} xs={4}  className="mt-4">
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="name">INT</p>
                                    </Col>

                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <div className="main-box">
                                            <div className="box green">
                                                <p className="wicket">W</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="name">DEL</p>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <div className="main-box">
                                            <div className="box green">
                                                <p className="wicket">W</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="name">LAJ</p>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>

                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <div className="main-box">
                                            <div className="box red">
                                                <p className="wicket">L</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="name">INT</p>
                                    </Col>

                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <div className="main-box">
                                            <div className="box red">
                                                <p className="wicket">L</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="score-board">
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <p className="name">REAL</p>
                                    </Col>

                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <FontAwesomeIcon icon={faHouse} className="icon-home" />
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} xs={4}>
                                        <div className="main-box">
                                            <div className="box yellow">
                                                <p className="wicket">D</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </SwiperSlide>
                </Swiper>
            </section>
        </>
    );
};

export default Exchange_Probability;
