import React, { useState } from "react";
import "./Match_Two_Content.css";
import Color_Box from "../Color_Box/Color_Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlay, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import MultiProgress from "react-multi-progress";

function Match_Two_Content() {
  const [activeMatchIndex, setActiveMatchIndex] = useState(null);
  const [activeGoalIndex, setActiveGoalIndex] = useState(null);

  const handleMatchClick = (index) => {
    setActiveMatchIndex(index); // Set the active index for match results
  };

  const handleGoalClick = (index) => {
    setActiveGoalIndex(index); // Set the active index for under/over goals
  };

  const [isToggled, setIsToggled] = useState(false);
  const [isScoreVisible, setIsScoreVisible] = useState(true); // State to control visibility of score

  const handleToggle = () => {
    setIsScoreVisible((prev) => !prev); // Toggle score visibility
    setIsToggled((prev) => !prev); // This still handles the column width
  };
  // Example data for match results
  const matches = [
    { id: 1, teams: ["ESP", "x", "GER"], scores: [2.19, 25, 2.19] },
    { id: 2, teams: ["ESP", "x", "GER"], scores: [2.19, 5, 2.19] },
  ];

  // Example data for under/over goals
  const goals = [
    { id: 1, teams: ["ESP", "GER"], scores: [2.19, 2.19] },
    { id: 2, teams: ["ESP", "GER"], scores: [2.19, 2.19] },
  ];



  return (
    <>
      <section className="Match_Two_Content">
        <div className="country-card">
          <div className="main-match-score">
            {/* <div className="close-btn">
              <FontAwesomeIcon className="close-icon" icon={faXmark} />
            </div>

            <div className="close-btn play-btn-holder" >
              <FontAwesomeIcon className="close-icon" icon={faPlay} />
            </div> */}
            <div className="match-score">
              <div className="row me-0 ms-0 justify-content-center ">
                <div
                  className={
                    isToggled
                      ? "col-xl-12 col-lg-12 col-md-12"
                      : "col-xl-5 col-lg-6 col-md-12"
                  }
                >
                  <div className="match-score-holder main-match-score-holder progress_holder" >
                    <div className="row">
                      <div className={isToggled ? "col-xl-3 " : "col-xl-4 "}>
                        <div className="match-score-country-holder">
                          <div className="row score-content">
                            <div className="col-md-12">
                              <div className="text-holder">
                                <p>Spain</p>
                              </div>
                              <div className="country-flag-holder">
                                <CircularProgressbar value={70} />
                                <img
                                  className="country-flag-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Images/Flags/flag-1.png"
                                  }
                                  alt="country-flag-img"
                                />
                              </div>
                            </div>

                            <div className={isToggled ? "d-none " : "col-md-12 "}>
                              <div className="color-box-holder">
                                <Color_Box
                                  letterText={"M"}
                                  backgroundColor={"#17b200"}
                                  textColor={"#fff"}
                                />
                                <Color_Box
                                  letterText={"L"}
                                  backgroundColor={"#E71C22"}
                                  textColor={"#fff"}
                                />
                                <Color_Box
                                  letterText={"M"}
                                  backgroundColor={"#17b200"}
                                  textColor={"#fff"}
                                />

                                <Color_Box
                                  letterText={"D"}
                                  backgroundColor={"#FEE12B"}
                                  textColor={"#000"}
                                />
                                <Color_Box
                                  letterText={"M"}
                                  backgroundColor={"#17b200"}
                                  textColor={"#fff"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={isToggled ? "col-xl-6 " : "col-xl-4 "}>
                        <div className="match-score-country-holder">
                          <div className="row score-content">
                            <div className="col-md-12 middle-score-section">
                              {isScoreVisible ? (
                                <>
                                  <div className="match_two_time-holder">
                                    <p> 02:30</p>
                                  </div>

                                  <div className="text-holder time-score-holder">
                                    <div>
                                      <p>
                                        <span className="first-team"> 0 </span>:
                                        <span className="two-team"> 1 </span>
                                      </p>
                                      <p>
                                        <span className="first-team"> 0 </span>:
                                        <span className="two-team"> 0 </span>
                                      </p>
                                      <p>
                                        <span className="first-team"> 3 </span>:
                                        <span className="two-team"> 0 </span>
                                      </p>
                                    </div>
                                  </div>
                                </> // Conditional rendering based on isScoreVisible
                              ) : (
                                <div className="multi-progress-bar-section">
                                  <div className="multi-progress-bar_holder">
                                    <div className="progressbar-lable-holder">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="label-text text-start">
                                            <p>38 % - ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-center">
                                            <p>X - 33%</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-end">
                                            <p>GER - 29 %</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <MultiProgress
                                      className="main_progress_bar_class"
                                      elements={[
                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#E6304E",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#F4C327",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#14C2A1",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },
                                      ]}
                                    />
                                  </div>

                                  <div className="multi-progress-bar_holder">
                                    <div className="progressbar-lable-holder">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="label-text text-start">
                                            <p>38 % - ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-center">
                                            <p>X - 33%</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-end">
                                            <p>GER - 29 %</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <MultiProgress
                                      className="main_progress_bar_class"
                                      elements={[
                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#E6304E",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#F4C327",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#14C2A1",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },
                                      ]}
                                    />
                                  </div>

                                  <div className="multi-progress-bar_holder">
                                    <div className="progressbar-lable-holder">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="label-text text-start">
                                            <p>38 % - ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-center">
                                            <p>X - 33%</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-end">
                                            <p>GER - 29 %</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <MultiProgress
                                      className="main_progress_bar_class"
                                      elements={[
                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#E6304E",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#F4C327",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#14C2A1",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },
                                      ]}
                                    />
                                  </div>

                                  <div className="multi-progress-bar_holder">
                                    <div className="progressbar-lable-holder">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="label-text text-start">
                                            <p>38 % - ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-center">
                                            <p>X - 33%</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-end">
                                            <p>GER - 29 %</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <MultiProgress
                                      className="main_progress_bar_class"
                                      elements={[
                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#E6304E",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#F4C327",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#14C2A1",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },
                                      ]}
                                    />
                                  </div>

                                  <div className="multi-progress-bar_holder">
                                    <div className="progressbar-lable-holder">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="label-text text-start">
                                            <p>38 % - ESP</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-center">
                                            <p>X - 33%</p>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-text text-end">
                                            <p>GER - 29 %</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <MultiProgress
                                      className="main_progress_bar_class"
                                      elements={[
                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#E6304E",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#F4C327",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#14C2A1",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },

                                        {
                                          value: 20,
                                          color: "#5D1628",
                                          className: "progress_bar_css",
                                          showPercentage: true,
                                          fontSize: 7,
                                          textColor: "white",
                                          isBold: true,
                                        },
                                      ]}
                                    />
                                  </div>
                                </div> // This will be shown when the score is hidden
                              )}

                              <div
                                className="bar-icon-holder"
                                onClick={handleToggle}
                              >
                                <FontAwesomeIcon
                                  className="bor-icon"
                                  icon={faBars}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={isToggled ? "col-xl-3 " : "col-xl-4 "}>
                        <div className="match-score-country-holder">
                          <div className="row score-content">
                            <div className="col-md-12">
                              <div className="text-holder">
                                <p>Germany</p>
                              </div>
                              <div className="country-flag-holder">
                                <CircularProgressbar value={90} />
                                <img
                                  className="country-flag-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Images/Flags/flag-1.png"
                                  }
                                  alt="country-flag-img"
                                />
                              </div>
                            </div>

                            <div className={isToggled ? "d-none " : "col-md-12 "}>
                              <div className="color-box-holder">
                                <Color_Box
                                  letterText={"M"}
                                  backgroundColor={"#17b200"}
                                  textColor={"#fff"}
                                />
                                <Color_Box
                                  letterText={"L"}
                                  backgroundColor={"#E71C22"}
                                  textColor={"#fff"}
                                />

                                <Color_Box
                                  letterText={"M"}
                                  backgroundColor={"#17b200"}
                                  textColor={"#fff"}
                                />
                                <Color_Box
                                  letterText={"D"}
                                  backgroundColor={"#FEE12B"}
                                  textColor={"#000"}
                                />
                                <Color_Box
                                  letterText={"M"}
                                  backgroundColor={"#17b200"}
                                  textColor={"#fff"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="result_section_holder">
            <div className="row justify-content-between me-0 ms-0">
              
              <div className="col-md-8 p-0">
                <div className="row me-0 ms-0">
                  <div className="col-md-12 p-0">
                    <div className="inner-heading-holder">
                      <h4>Fulltime Result</h4>
                    </div>
                  </div>

                  {matches.map((match, index) => (
                    <div
                      key={match.id}
                      className={`col-md-12 p-0 result-data-holder ${activeGoalIndex === index ? "active" : ""
                        }`}
                      onClick={() => handleGoalClick(index)}
                    >
                      <div className="row justify-content-start">
                        <div className="col-md-11">
                          <div className="result-data-holder-main">
                            <div className="main-result-row-holder">
                              <div className="row main-result-row-holder">
                                <div className="col-md-3">
                                  <div className="match-score">
                                    <div className="row me-0 ms-0 justify-content-center">
                                      <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className="match-score-holder">
                                          <div className="row">
                                            <div className="col-md-4">
                                              <div className="match-score-country-holder">
                                                <div className="row score-content">
                                                  <div className="col-md-12">
                                                    <div className="text-holder">
                                                      <p>{match.teams[0]}</p>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-12 p-0">
                                                    <div className="country-flag-holder">
                                                      <img
                                                        className="country-flag-img"
                                                        src={
                                                          process.env
                                                            .PUBLIC_URL +
                                                          "/assets/Images/Flags/flag-1.png"
                                                        }
                                                        alt="country-flag-img"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="match-score-country-holder">
                                                <div className="row score-content">
                                                  <div className="col-md-12">
                                                    <div className="text-holder time-score-holder">
                                                      <div>
                                                        <p>vs</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="match-score-country-holder">
                                                <div className="row score-content">
                                                  <div className="col-md-12">
                                                    <div className="text-holder">
                                                      <p>{match.teams[2]}</p>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-12 p-0">
                                                    <div className="country-flag-holder">
                                                      <img
                                                        className="country-flag-img"
                                                        src={
                                                          process.env
                                                            .PUBLIC_URL +
                                                          "/assets/Images/Flags/flag-1.png"
                                                        }
                                                        alt="country-flag-img"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="name-score-text-holder">
                                    <div>
                                      <p>{match.teams[0]}</p>
                                      <p>{match.scores[0]}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="name-score-text-holder">
                                    <div>
                                      <p>{match.teams[1]}</p>
                                      <p>{match.scores[1]}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="name-score-text-holder">
                                    <div>
                                      <p>{match.teams[2]}</p>
                                      <p>{match.scores[2]}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              <div className="col-md-4 p-0">
                <div className="row me-0 ms-0">
                  <div className="col-md-12 p-0">
                    <div className="inner-heading-holder">
                      <h4>Under/Over Goals: 2.5</h4>
                    </div>
                  </div>



                  {goals.map((goal, index) => (
                    <div
                      key={goal.id}
                      className={`col-md-12 p-0 result-data-holder ${activeGoalIndex === index ? "active" : ""
                        }`}
                      onClick={() => handleGoalClick(index)} // Call handleClick with the current index
                    >
                      <div className="row justify-content-end">
                        <div className="col-md-11">
                          <div className="result-data-holder-main">
                            <div className="main-result-row-holder">
                              <div className="row main-result-row-holder">
                                <div className="col-md-5">
                                  <div className="name-score-text-holder">
                                    <div>
                                      <p>{goal.teams[0]}</p>
                                      <p>{goal.scores[0]}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-5">
                                  <div className="name-score-text-holder">
                                    <div>
                                      <p>{goal.teams[1]}</p>
                                      <p>{goal.scores[1]}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="add-holder">
                                    <div className="add-icon-holder">
                                      <FontAwesomeIcon
                                        className="add-icon"
                                        icon={faPlus}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>


            </div>
          </div>


        </div>
      </section>
    </>
  );
}

export default Match_Two_Content;
