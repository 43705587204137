import React, { useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Success_Modal from "../../Common_Component/Common_Modal/Success_Modal/Success_Modal";
import { Context } from "../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";

function Set_Password(props) {

  const {
    postData,
    setSignin,
    Cookies
  } = useContext(Context);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmitClick = () => {
    props.onHide();
    setSuccessShow(true);
    setTimeout(() => {
      setSuccessShow(false);
      navigate("/");
    }, 3000);
  };

  const handleBackClick = () => {
    props.onHide();
    props.resetModal();
  };

  const onSubmit = async (data) => {
    try {
      if (data?.new_password === data?.confirm_password) {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phonePattern = /^[0-9]{10}$/;

        if (emailPattern.test(props?.email_or_contact)) {
          const emailOtpResponse = await postData(`/auth/forget-password-email`,
            { email: props?.email_or_contact, new_password: data?.new_password });
          if (emailOtpResponse?.success) {
            props.onHide()
            console.log('Password Updated');
            setSuccessShow(true);
            setTimeout(() => {
              setSuccessShow(false);
              navigate("/");
            }, 3000);
          } else {
            console.log('Failed to Update Password for email');
            setError("new_password", {
              type: "manual",
              message: "Failed to update password. Please try again.",
            });
          }
        } else if (phonePattern.test(props?.email_or_contact)) {
          const mobileOtpResponse = await postData(`/auth/forget-password-contact`,
            { contact_no: props?.email_or_contact, new_password: data?.new_password });
          if (mobileOtpResponse?.success) {
            console.log('Password Updated');
            setSuccessShow(true);
            setTimeout(() => {
              setSuccessShow(false);
              navigate("/");
            }, 3000);
          } else {
            console.log('Failed to Update Password for mobile');
            setError("new_password", {
              type: "manual",
              message: "Failed to update password. Please try again.",
            });
          }
        }
      } else {
        setError("confirm_password", {
          type: "manual",
          message: "Password does not match.",
        });
      }
    } catch (error) {
      console.log(error);
      setError("new_password", {
        type: "manual",
        message: "An error occurred while updating the password.",
      });
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Login_Modal Common_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <div className="modal-bg-img">
          <img
            className="modal-bg"
            src={process.env.PUBLIC_URL + "/assets/Images/Lohin_Modal/warri-sports.png"}
            alt="modal-bg"
          />
        </div>
        <Modal.Body>
          <div className="back-icon-holder">
            <FontAwesomeIcon className="back-icon" icon={faAngleLeft} onClick={() => handleBackClick()} />
          </div>
          <div className="modal_heading">
            <h4>Set New Password</h4>
          </div>

          <div className="form-holder">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="form-field" controlId="">
                <Form.Label>Enter New Password</Form.Label>
                <div className="password-field-holder">
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter Password*"
                    name="new_password"
                    {...register("new_password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                      },
                    })}
                    className={classNames("", { "is-invalid": errors?.new_password })}
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </div>
                </div>
                {errors?.new_password && (
                  <sup className="text-danger">{errors?.new_password?.message}</sup>
                )}
              </Form.Group>

              <Form.Group className="form-field" controlId="">
                <Form.Label>Confirm Password</Form.Label>
                <div className="password-field-holder">
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Confirm Password*"
                    name="confirm_password"
                    {...register("confirm_password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                      },
                    })}
                    className={classNames("", { "is-invalid": errors?.confirm_password })}
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </div>
                </div>
                {errors?.confirm_password && (
                  <sup className="text-danger">{errors?.confirm_password?.message}</sup>
                )}

              </Form.Group>

              <Button className="common-btn" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Success_Modal
        text={"Password Reset Successfully"}
        show={successShow}
        onHide={() => setSuccessShow(false)}
      />
    </>
  );
}

export default Set_Password;
