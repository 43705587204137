import React from "react";
import { Accordion, Button, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import IncreamentDecreament from "../../../../../Common_Component/IncreamentDecreament/IncreamentDecreament";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function Exchange_Live_Center_my_Bet_Section() {
  return (
    <>
      <section className="Exchange_Live_Center_my_Bet_Section">
        <div className="My-Bets">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span className="main-text">My Bets</span>
              </Accordion.Header>
              <Accordion.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="pills" className="">
                    <Nav.Item className="orange-team">
                      <Nav.Link eventKey="first" className="orange-team">
                        <span>Place Back</span> <br></br> (Bet For)
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="green-team">
                      <Nav.Link eventKey="second" className="green-team">
                        <span>Place Lay</span> <br></br> (Bet Against)
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="orange-team">
                        <Accordion
                          defaultActiveKey={["0"]}
                          alwaysOpen
                          className="orange-team mt-2"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div class="min-heading">
                                <p class="title">Back (Bet For)</p>
                                <div class="wrapper">
                                  <div class="corners corners--slanted"></div>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="orange-team-main  first mb-3">
                                <p className="text">
                                  FK Rigas Futbola Skola – Bodoe/Glimt
                                </p>
                                <div className="delete-main">
                                  <p className="multiply me-3">
                                    1x2 <span className="text">Draw</span>
                                  </p>
                                  <div className="main-icon">
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="icon-delete"
                                    />
                                  </div>
                                </div>
                                <Row>
                                  <Col xl={5} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      BACK
                                    </Form.Label>

                                    <IncreamentDecreament />
                                  </Col>

                                  <Col xl={3} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      STAKE
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      id="inputPassword5"
                                      aria-describedby="passwordHelpBlock"
                                    />
                                  </Col>

                                  <Col xl={3} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      PROFIT
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      id="inputPassword5"
                                      aria-describedby="passwordHelpBlock"
                                    />
                                  </Col>

                                </Row>

                                <Row className="payment-bet mt-1">
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      200
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      300
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      400
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      1000
                                    </Button>
                                  </Col>

                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                </Row>
                                <div className="button-main mt-2 mb-2">
                                  <Button variant="primary">Reset</Button>
                                  <Button variant="secondary">Submit</Button>
                                </div>
                              </div>

                              <div className="orange-team-main">
                                <p className="text">
                                  FK Rigas Futbola Skola – Bodoe/Glimt
                                </p>
                                <div className="delete-main">
                                  <p className="multiply me-3">
                                    1x2 <span className="text">Draw</span>
                                  </p>
                                  <div className="main-icon">
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="icon-delete"
                                    />
                                  </div>
                                </div>
                                <Row>
                                  <Col xl={5} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      BACK
                                    </Form.Label>

                                    <IncreamentDecreament />
                                  </Col>
                                  <Col xl={3} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      STAKE
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      id="inputPassword5"
                                      aria-describedby="passwordHelpBlock"
                                    />
                                  </Col>
                                  <Col xl={3} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      PROFIT
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      id="inputPassword5"
                                      aria-describedby="passwordHelpBlock"
                                    />
                                  </Col>
                                </Row>

                                <Row className="payment-bet mt-1">
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>

                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary orange">
                                      100
                                    </Button>
                                  </Col>
                                </Row>
                                <div className="button-main mt-2 mb-2">
                                  <Button variant="primary">Reset</Button>
                                  <Button variant="secondary">Submit</Button>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="green-team">
                        <Accordion
                          defaultActiveKey={["0"]}
                          alwaysOpen
                          className="green-team mt-2"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div class="min-heading">
                                <p class="title">Back (Bet For)</p>
                                <div class="wrapper">
                                  <div class="corners corners--slanted"></div>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="green-team-main  first mb-3">
                                <p className="text">
                                  FK Rigas Futbola Skola – Bodoe/Glimt
                                </p>
                                <div className="delete-main">
                                  <p className="multiply me-3">
                                    1x2 <span className="text">Draw</span>
                                  </p>
                                  <div className="main-icon">
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="icon-delete"
                                    />
                                  </div>
                                </div>
                                <Row>
                                  <Col xl={5} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      BACK
                                    </Form.Label>

                                    <IncreamentDecreament />
                                  </Col>
                                  <Col xl={3} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      STAKE
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      id="inputPassword5"
                                      aria-describedby="passwordHelpBlock"
                                    />
                                  </Col>
                                  <Col xl={3} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      PROFIT
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      id="inputPassword5"
                                      aria-describedby="passwordHelpBlock"
                                    />
                                  </Col>
                                </Row>

                                <Row className="payment-bet mt-1">
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>

                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                </Row>
                                <div className="button-main mt-2 mb-2">
                                  <Button variant="primary">Reset</Button>
                                  <Button variant="secondary">Submit</Button>
                                </div>
                              </div>
                              <div className="green-team-main">
                                <p className="text">
                                  FK Rigas Futbola Skola – Bodoe/Glimt
                                </p>
                                <div className="delete-main">
                                  <p className="multiply me-3">
                                    1x2 <span className="text">Draw</span>
                                  </p>
                                  <div className="main-icon">
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="icon-delete"
                                    />
                                  </div>
                                </div>
                                <Row>
                                  <Col xl={5} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      BACK
                                    </Form.Label>
                                    <div className="green-team">
                                      <IncreamentDecreament />
                                    </div>
                                  </Col>
                                  <Col xl={3} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      STAKE
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      id="inputPassword5"
                                      aria-describedby="passwordHelpBlock"
                                    />
                                  </Col>
                                  <Col xl={3} md={12}>
                                    <Form.Label htmlFor="inputPassword5">
                                      PROFIT
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      id="inputPassword5"
                                      aria-describedby="passwordHelpBlock"
                                    />
                                  </Col>
                                </Row>

                                <Row className="payment-bet mt-1">
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>

                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                  <Col
                                    xxl={2}
                                    xl={3}
                                    lg={3}
                                    xs={3}
                                    className="p-1"
                                  >
                                    <Button variant="primary green">100</Button>
                                  </Col>
                                </Row>
                                <div className="button-main mt-2 mb-2">
                                  <Button variant="primary">Reset</Button>
                                  <Button variant="secondary">Submit</Button>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
    </>
  );
}

export default Exchange_Live_Center_my_Bet_Section;
