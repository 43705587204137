import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Virtual_home from "../Virtual-components/Virtual-home/Virtual_home";

const Virtual_routes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Virtual_home />} />
            </Routes>
        </>
    )
}

export default Virtual_routes