import React from 'react'
import './Virtual_home.css'
import Virtual_game_card from './Virtual-game-card/Virtual_game_card'
import Virtual_breadcrumb from '../Virtual-breadcrumb/Virtual_breadcrumb'

const Virtual_home = () => {


    return (
        <>
            <Virtual_breadcrumb />
            <Virtual_game_card />

            <div className='virt-home-log container'>
                <div className='row'>
                    <div className='col-md-4 col-8 mx-auto'>
                        <img className='logoimg' src={process.env.PUBLIC_URL + '/assets/Images/Header/betzone-logo.png'} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Virtual_home