import React, { useContext, useState } from "react";
import './Exchange_home.css'
import { useLocation } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";


const Exchange_home = () => {

    const location = useLocation();
    return (
        <>
            <section className="ex-home-main-section">
                <div className="container-fluid p-0">
                    <div className="breadcrumbdiv">
                        <Breadcrumb>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">Football</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="gamenamediv">
                            <p className="gamename"><img className="fotticc" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/football2.png'} />Football</p>
                        </div>
                    </div>


                    <div className="games-accodion-sect">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>International Clubs - UEFA Champions League</Accordion.Header>
                                <Accordion.Body>
                                    <div className="innerbet-body">

                                        <div className="row mb-3">
                                            <div className="col-xxl-6 col-xl-6 col-lg-12">
                                                <div className="todays-matchsec">
                                                    <Link to="/exchange/match-bet">
                                                        <div className="d-flex">
                                                            <div className="time-div">
                                                                <p className="day">Today</p>
                                                                <p className="day">23:20</p>
                                                            </div>
                                                            <div className="game-det-div">
                                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                                <p className="matchname">Bodoe/Glimt</p>
                                                                <p className="matched">Matched:<span>₹0</span></p>

                                                                <img className="flashicn" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/flash.png'} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-12">
                                                <div className="betcontdiv">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">FK Rigas Futbol...</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Draw</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Bodoe/Glimt</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-xxl-6 col-xl-6 col-lg-12">
                                                <div className="todays-matchsec">
                                                    <Link to="/exchange/match-bet">
                                                        <div className="d-flex">
                                                            <div className="time-div">
                                                                <p className="day">Today</p>
                                                                <p className="day">23:20</p>
                                                            </div>
                                                            <div className="game-det-div">
                                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                                <p className="matchname">Bodoe/Glimt</p>
                                                                <p className="matched">Matched:<span>₹0</span></p>

                                                                <img className="flashicn" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/flash.png'} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-12">
                                                <div className="betcontdiv">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">FK Rigas Futbol...</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Draw</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Bodoe/Glimt</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-xxl-6 col-xl-6 col-lg-12">
                                                <div className="todays-matchsec">
                                                    <Link to="/exchange/match-bet">
                                                        <div className="d-flex">
                                                            <div className="time-div">
                                                                <p className="day">Today</p>
                                                                <p className="day">23:20</p>
                                                            </div>
                                                            <div className="game-det-div">
                                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                                <p className="matchname">Bodoe/Glimt</p>
                                                                <p className="matched">Matched:<span>₹0</span></p>

                                                                <img className="flashicn" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/flash.png'} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-12">
                                                <div className="betcontdiv">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">FK Rigas Futbol...</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Draw</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Bodoe/Glimt</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="1">
                                <Accordion.Header>International Clubs - UEFA Champions League</Accordion.Header>
                                <Accordion.Body>
                                    <div className="innerbet-body">

                                        <div className="row mb-3">
                                            <div className="col-lg-6">
                                                <div className="todays-matchsec">
                                                    <Link to="/exchange/match-bet">
                                                        <div className="d-flex">
                                                            <div className="time-div">
                                                                <p className="day">Today</p>
                                                                <p className="day">23:20</p>
                                                            </div>
                                                            <div className="game-det-div">
                                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                                <p className="matchname">Bodoe/Glimt</p>
                                                                <p className="matched">Matched:<span>₹0</span></p>

                                                                <img className="flashicn" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/flash.png'} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            </div>
                                            <div className="col-lg-6">
                                                <div className="betcontdiv">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">FK Rigas Futbol...</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Draw</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Bodoe/Glimt</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-lg-6">
                                                <div className="todays-matchsec">
                                                    <Link to="/exchange/match-bet">
                                                        <div className="d-flex">
                                                            <div className="time-div">
                                                                <p className="day">Today</p>
                                                                <p className="day">23:20</p>
                                                            </div>
                                                            <div className="game-det-div">
                                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                                <p className="matchname">Bodoe/Glimt</p>
                                                                <p className="matched">Matched:<span>₹0</span></p>

                                                                <img className="flashicn" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/flash.png'} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            </div>
                                            <div className="col-lg-6">
                                                <div className="betcontdiv">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">FK Rigas Futbol...</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Draw</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Bodoe/Glimt</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-lg-6">
                                                <div className="todays-matchsec">
                                                    <Link to="/exchange/match-bet">
                                                        <div className="d-flex">
                                                            <div className="time-div">
                                                                <p className="day">Today</p>
                                                                <p className="day">23:20</p>
                                                            </div>
                                                            <div className="game-det-div">
                                                                <p className="matchname">FK Rigas Futbola Skola</p>
                                                                <p className="matchname">Bodoe/Glimt</p>
                                                                <p className="matched">Matched:<span>₹0</span></p>

                                                                <img className="flashicn" src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/flash.png'} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            </div>
                                            <div className="col-lg-6">
                                                <div className="betcontdiv">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">FK Rigas Futbol...</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Draw</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="betdtiv">
                                                                <p className="name">Bodoe/Glimt</p>
                                                                <div className="numbdiv">
                                                                    <div className="d-flex">
                                                                        <div className="first">
                                                                            <p className="yellow">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                        <div className="first">
                                                                            <p className="green">17</p>
                                                                            <p className="smtest">11.77</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="2">
                                <Accordion.Header>International Clubs - UEFA Champions League</Accordion.Header>

                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header>International Clubs - UEFA Champions League</Accordion.Header>

                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                                <Accordion.Header>International Clubs - UEFA Champions League</Accordion.Header>

                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                                <Accordion.Header>International Clubs - UEFA Champions League</Accordion.Header>

                            </Accordion.Item>

                        </Accordion>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Exchange_home