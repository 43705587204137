import React from "react";
import "./Color_Box.css";

function Color_Box({ letterText, backgroundColor, textColor }) {
    const styleCss = {
        backgroundColor: backgroundColor,
        color: textColor, // Set the text color here
      };
  return (
    <>
      <section className="color-box" style={styleCss}>
        <p >{letterText}</p>
      </section>
    </>
  );
}

export default Color_Box;
