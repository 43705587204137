import React from "react";
import "./Home_Counter.css";

function Home_Counter() {
  return (
    <>
      <section className="Home_Counter">
        <div className="container">
          <div className="Home_Counter_holder">
            <div className="row">
              <div className="col-md-4 col-sm-6 counter-card">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-5 col-4 ">
                    <div className="img-holder">
                      <img
                        className="counter-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Home/Counter/rupees.png"
                        }
                        alt="counter-img"
                      />
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-7 col-sm-7  col-8 ">
                    <div className="text-holder">
                      <div>
                        <h3>$1304,941</h3>
                        <p>Paid Out Prize in Total</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 counter-card">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-5 col-4 ">
                    <div className="img-holder">
                      <img
                        className="counter-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Home/Counter/Trophy.png"
                        }
                        alt="counter-img"
                      />
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-7 col-sm-7  col-8">
                    <div className="text-holder">
                      <div>
                        <h3>76,752</h3>
                        <p>Winners</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 counter-card">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-5 col-4">
                    <div className="img-holder">
                      <img
                        className="counter-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Home/Counter/group.png"
                        }
                        alt="counter-img"
                      />
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-7 col-sm-7 col-8">
                    <div className="text-holder">
                      <div>
                        <h3>4,392</h3>
                        <p>Players online</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home_Counter;
