import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getSports = async () => {
  try {
    return await getData(`/without-login/thired-party/getSports`);
  } catch (error) {
    console.log(error);
  }
};

export const getAllLive = async () => {
  try {
    return await getData(`/without-login/thired-party/getAllLive`);
  } catch (error) {
    console.log(error);
  }
};

export const getEvents = async (id) => {
  try {
    return await getData(`/without-login/thired-party/getEvents/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getMarkets = async (id) => {
  try {
    return await getData(`/without-login/thired-party/getMarkets/${id}`);
  } catch (error) {
    console.log(error);
  }
};
