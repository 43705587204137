import React, { useState } from 'react'
import './Mob_bot_head.css'
import { Link } from 'react-router-dom'

import Ex_left_sidebar_offcanvas from '../../Exchange/Exchange-components/Exchange-common_components/Offcanvas/Ex_left_sidebar_offcanvas/Ex_left_sidebar_offcanvas'

import EX_right_mybet_sidebar from '../../Exchange/Exchange-components/Exchange-common_components/Offcanvas/EX_right_mybet_sidebar/EX_right_mybet_sidebar'

import Ex_right_sidebar_offcanvas from '../../Exchange/Exchange-components/Exchange-common_components/Offcanvas/Ex_right_sidebar_offcanvas/Ex_right_sidebar_offcanvas'

const Mob_bot_head = () => {

    // const [modalShow, setModalShow] = React.useState(false);

    const [leftsidebarShow, setLeftsidebarShow] = useState(false);
    const [mybetShow, setMybetShow] = useState(false);
    const [rightsidebarShow, setRightsidebarShow] = useState(false);

    return (
        <>
            <div className='exchangemobmenudiv'>
                <div className='menudiv'>
                    <div className='menulist'>
                        <div className='nav'>
                            <Link to="#" onClick={() => setLeftsidebarShow(true)}>
                                <img className='menuicon' src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/mobileview_menuicons/toggle.png'} />
                            </Link>
                        </div>
                        <div className='nav'>
                            <Link to="#" onClick={() => setMybetShow(true)}>
                                <img className='menuicon' src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/mobileview_menuicons/online-streaming.png'} />
                            </Link>
                        </div>
                        <div className='nav'>
                            <Link to="/exchange/home">
                                <img className='menuiconhome menuicon' src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/mobileview_menuicons/home.png'} />
                            </Link>
                        </div>
                        <div className='nav'>
                            <Link to="#" onClick={() => setRightsidebarShow(true)}>
                                <img className='menuicon' src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/mobileview_menuicons/ticket.png'} />
                            </Link>
                        </div>
                        <div className='nav'>
                            <Link to="/exchange/profile">
                                <img className='menuicon' src={process.env.PUBLIC_URL + '/assets/Images/Exchange/icons/mobileview_menuicons/profile.png'} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Ex_left_sidebar_offcanvas
                show={leftsidebarShow}
                onHide={() => setLeftsidebarShow(false)}

            />
            <EX_right_mybet_sidebar
                show={mybetShow}
                onHide={() => setMybetShow(false)}
            />

            <Ex_right_sidebar_offcanvas
                show={rightsidebarShow}
                onHide={() => setRightsidebarShow(false)}
            />
        </>
    )
}

export default Mob_bot_head