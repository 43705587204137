import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./exchangeprofile.css";
import "./exchangeprofile2.css";
import Successfull_Lottie from "../../Animation/Successfull_Lottie/Successfull_Lottie";
const ButtonValues = () => {
    const [showNote, setShowNote] = useState(false);

    const handleClick = () => {
        setShowNote(true);
        setTimeout(() => {
            setShowNote(false);
        }, 3000);
    };
    return (
        <>
            <section className="exchangeprofilemain-sec">
                <div className="secttiill">
                    <div className="titlediv">
                        <p className="title">Change Button Values</p>
                    </div>
                </div>
                <div className="Button-Values">
                    <Row>
                        <Col xxl={3} xl={4} lg={5} md={4}>
                            <p className="label">Price Lable</p>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control type="text" placeholder="100" className="mb-2" />
                                    <Form.Control type="text" placeholder="200" className="mb-2" />
                                    <Form.Control type="text" placeholder="300" className="mb-2" />
                                    <Form.Control type="text" placeholder="400" className="mb-2" />
                                    <Form.Control type="text" placeholder="1000" className="mb-2" />
                                    <Form.Control type="text" placeholder="1500" className="mb-2" />
                                    <Form.Control type="text" placeholder="2000" className="mb-2" />
                                    <Form.Control type="text" placeholder="25000" className="mb-2" />
                                    <Form.Control type="text" placeholder="3000" className="mb-2" />
                                    <Form.Control type="text" placeholder="3500" className="mb-2" />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col xxl={1} xl={1} lg={1} md={1}></Col>
                        <Col xxl={3} xl={4} lg={5} md={4}>
                            <p className="label">Price Value</p>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control type="number" placeholder="100" className="mb-2" />
                                    <Form.Control type="number" placeholder="200" className="mb-2" />
                                    <Form.Control type="number" placeholder="300" className="mb-2" />
                                    <Form.Control type="number" placeholder="400" className="mb-2" />
                                    <Form.Control type="number" placeholder="1000" className="mb-2" />
                                    <Form.Control type="number" placeholder="1500" className="mb-2" />
                                    <Form.Control type="number" placeholder="2000" className="mb-2" />
                                    <Form.Control type="number" placeholder="25000" className="mb-2" />
                                    <Form.Control type="number" placeholder="3000" className="mb-2" />
                                    <Form.Control type="number" placeholder="3500" className="mb-2" />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <div className="Values">
                    <div className="sect tiill">
                        <div className="titlediv" onClick={handleClick}>
                            <p className="title">Update Price Value</p>
                        </div>
                        {showNote && (
                            <div className="note-main slide-left">
                                <div className="note">
                                    <div className="note-content">
                                        <Successfull_Lottie />
                                        <p className="pass">Button Changed Succefully</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ButtonValues;
