import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './Ex_left_sidebar_offcanvas.css'

import Exchange_sidebar from '../../../../Exchange-layout/Exchange-sidebar/Exchange_sidebar';


const Ex_left_sidebar_offcanvas = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            {['start'].map((placement, idx) => (
                <Offcanvas className="Ex_left_sidebar_offcanvas" show={show} onHide={handleClose} {...props} key={idx} placement={placement} name={placement}>
                    <Offcanvas.Header closeButton>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='ex-sidebar'>
                            <Exchange_sidebar />
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            ))}
        </>
    )
}

export default Ex_left_sidebar_offcanvas