import React from "react";
import { Accordion, Button, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import IncreamentDecreament from "../../../../../../Common_Component/IncreamentDecreament/IncreamentDecreament";
import { Link } from "react-router-dom";

function Live_Live_Center_my_Bet_Section() {
  return (
    <>
      <section className="Exchange_Live_Center_my_Bet_Section">
        <div className="My-Bets">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span className="main-text">My Bets</span>
              </Accordion.Header>
              <Accordion.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  {/* <Nav variant="pills" className="">
                    <Nav.Item className="orange-team">
                      <Nav.Link eventKey="first" className="orange-team">
                        <span>Place Back</span> <br></br> (Bet For)
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="green-team">
                      <Nav.Link eventKey="second" className="green-team">
                        <span>Place Lay</span> <br></br> (Bet Against)
                      </Nav.Link>
                    </Nav.Item>
                  </Nav> */}

                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="orange-team">
                        <Accordion
                          defaultActiveKey={["0"]}
                          alwaysOpen
                          className="orange-team mt-2"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div class="min-heading">
                                <p class="title">
                                  Bets <span className="noofbets">3</span>
                                </p>
                                <div class="wrapper">
                                  <div class="corners corners--slanted"></div>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="orange-team-main  first mb-3">
                                <div className="acmilandiv">
                                  <div className="delete-main">
                                    <p className="text">AC Milan (Raul)</p>
                                    <div className="main-icon">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="icon-delete"
                                      />
                                    </div>
                                  </div>

                                  <p className="text">
                                    Win/Draw/Win, Regular Time{" "}
                                  </p>
                                  <Link className="text">
                                    AC Milan (Raul) vs. FC Inter Milano (Prince)
                                  </Link>

                                  <Row>
                                    <Col xs={9} className="pe-0">
                                      <Form.Label htmlFor="inputPassword5">
                                        STAKE
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        id="inputPassword5"
                                        aria-describedby="passwordHelpBlock"
                                        placeholder="Enter stake value"
                                        className="stakeinpp"
                                      />
                                      <span className="egfefgfg">Returns</span>
                                    </Col>
                                    <Col xs={3} className="mt-auto">
                                      <Form.Label htmlFor="inputPassword5"></Form.Label>
                                      <button className="stvalebt">
                                        @ 1.25
                                      </button>
                                      <span className="egfefgfg">RM46.00</span>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="acmilandiv">
                                  <div className="delete-main">
                                    <p className="text">AC Milan (Raul)</p>
                                    <div className="main-icon">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="icon-delete"
                                      />
                                    </div>
                                  </div>

                                  <p className="text">
                                    Win/Draw/Win, Regular Time{" "}
                                  </p>
                                  <Link className="text">
                                    AC Milan (Raul) vs. FC Inter Milano (Prince)
                                  </Link>

                                  <Row>
                                    <Col xs={9} className="pe-0">
                                      <Form.Label htmlFor="inputPassword5">
                                        STAKE
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        id="inputPassword5"
                                        aria-describedby="passwordHelpBlock"
                                        placeholder="Enter stake value"
                                        className="stakeinpp"
                                      />
                                      <span className="egfefgfg">Returns</span>
                                    </Col>
                                    <Col xs={3} className="mt-auto">
                                      <Form.Label htmlFor="inputPassword5"></Form.Label>
                                      <button className="stvalebt">
                                        @ 1.25
                                      </button>
                                      <span className="egfefgfg">RM46.00</span>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
    </>
  );
}

export default Live_Live_Center_my_Bet_Section;
