import React, { useEffect, useState } from "react";
import "./Event_details.css";
import Event_details_card from "./Event_details_card/Event_details_card";
import { getMarkets } from "../../../../../utils/apis/games";

function Event_details({ eventDetailsId, eventDetails, getValues, setValue }) {
  const [market, setmarket] = useState([]);

  const Markets = async (id) => {
    const res = await getMarkets(id);
    setmarket(res?.data);
  };
  useEffect(() => {
    if (getValues("event_id")) {
      Markets(getValues("event_id"));
      const intervalId = setInterval(() => {
        Markets(getValues("event_id"));
      }, 5000);

      return () => clearInterval(intervalId);
    }

    // if (eventDetailsId) {
    //   Markets(eventDetailsId);
    // }
  }, [getValues("event_id")]);

  // console.log(market?.length);
  // console.log(eventDetailsId);

  // const [market, setMarket] = useState([]);
  // const [eventId, setEventId] = useState(getValues("event_id")); // Track the event_id in state

  // // Function to fetch markets for a given event ID
  // const Markets = async (id) => {
  //   const res = await getMarkets(id);
  //   setMarket(res?.data);
  // };

  // // Fetch markets based on event_id and set interval for periodic updates
  // useEffect(() => {
  //   const currentEventId = getValues("event_id");

  //   // Check if event_id is available and different from the previous one
  //   if (currentEventId && currentEventId !== eventId) {
  //     setEventId(currentEventId); // Update state to trigger re-fetch
  //     Markets(currentEventId); // Fetch markets for the new event_id
  //   }

  //   // Set up periodic fetching of markets every 5 seconds
  //   const intervalId = setInterval(() => {
  //     if (getValues("event_id") !== eventId) {
  //       const newEventId = getValues("event_id");
  //       setEventId(newEventId); // Update eventId state
  //       Markets(newEventId); // Fetch new markets
  //     }
  //   }, 5000);

  //   // Cleanup interval on unmount or when event_id changes
  //   return () => clearInterval(intervalId);
  // }, [eventId, getValues]);

  return (
    <>
      <section className="Event_details">
        <div className="Event_details_content_card mb-4">
          <Event_details_card event={getValues("event")} market={market} />
        </div>
      </section>
    </>
  );
}

export default Event_details;
