import React from "react";
import Exchange_bet_sidebar from "../Exchange/Exchange-layout/Exchange-bet-sidebar/Exchange_bet_sidebar";
import Live_breadcrumb from "./Live_components/Live_breadcrumb/Live_breadcrumb";
import Live_bet_sidebar from "./Live_components/Live_home/Live-bet-sidebar/Live_bet_sidebar";
import Live_home from "./Live_components/Live_home/Live_home";

function Live() {
  return (
    <>
      <section className="Live">
        <div className="container-fluid p-0">
          <div className="row me-0 ms-0">
            <div className="col-xxl-9 col-xl-9 col-lg-9   p-0 order-1 order-md-1 ">
              <div className="live-content-holder">
                <Live_breadcrumb />
                <Live_home />
              </div>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-3   p-0 order-2 order-md-2 ">
              <div className="ex-last-div">
                <Live_bet_sidebar />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Live;
