import React, { useState } from "react";

import "./exchangeprofile.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

const ProfitLoss = () => {
  const [show, setShow] = React.useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    <>
      <section className="exchangeprofilemain-sec">
        <div className="secttiill">
          <div className="titlediv">
            <p className="title">Profit Loss</p>
          </div>
        </div>

        <div className="tablemain-div">
          <div className="row">
            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="Select Date"
                />

                <div className="calender-icon_holder">
                  <FontAwesomeIcon
                    className="calender-icon"
                    icon={faCalendarDays}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  name="deadline"
                  id="deadline"
                  autoComplete="off"
                  className="form-control"
                  placeholderText="Select Date"
                />
                <div className="calender-icon_holder">
                  <FontAwesomeIcon
                    className="calender-icon"
                    icon={faCalendarDays}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-3">
              <div className="formgroup">
                <button className="submitbtnn">Submit</button>
              </div>
            </div>
          </div>

          <div className="table-div">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Event Type</th>
                    <th>Event Name</th>
                    <th>Amount</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>1</td>
                    <td>22</td>
                    <td>Credit</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfitLoss;
